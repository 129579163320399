<template>
 <div>
   <h2>Personal Account Types</h2>
   <v-divider class="mb-4"></v-divider>
   <p>These account types will be made available on the debt service ratio and net worth pages. </p>
   <v-dialog v-model="modal" persistent max-width="580">
    <v-card>
      <v-card-title class="headline pb-2 mb-2 mt-2 pt-2">{{formTitle}}</v-card-title>

      <v-card-text class="mb-4">
        <va-input 
          v-model="item.account_name"
          v-validate="'required'" 
          data-vv-scope="account"
          data-vv-as="account name"
          placeholder=" "
          label="Account Name"
          :error-messages="errors.collect('account.account_name')"
          data-vv-name="account_name"/>

        <va-select 
          v-model="item.account_type"
          v-validate="'required'" 
          :disabled="disableType"
          data-vv-scope="account"
          placeholder=" "
          label="Account Type"
          :error-messages="errors.collect('account.type')"
          :items="accountTypes"
          data-vv-name="type"/>

        <div  v-if="item.account_type === 'Deduction'">
          <v-layout >
            <v-checkbox class="mt-0" v-model="item.dependable" label="Dependable"></v-checkbox>
            <v-checkbox class="mt-0" v-model="item.is_flat" label="Flat amount"></v-checkbox>
          </v-layout>
          <v-layout> 
            <va-select
              class="mr-3" 
              v-model="item.deduction_type"
              v-validate="item.account_type == 'Deduction' ? 'required' : ''" 
              data-vv-scope="account"
              data-vv-as="deduction type"
              placeholder=" "
              label="Deduction Type"
              :error-messages="errors.collect('account.deduction_type')"
              :items="['Statutory', 'Non-Statutory']"
              data-vv-name="deduction_type"/>

            <va-input 
              v-show="item.account_type == 'Deduction' && !item.is_flat"
              v-model="item.rate"
              v-validate="item.account_type == 'Deduction' && !item.is_flat? 'required|decimal|min_value:0|max_value:100' : ''" 
              data-vv-scope="account"
              data-vv-name="rate"
              :width="'340px'"
              placeholder=" "
              label="Rate (%)"
              :error-messages="errors.collect('account.rate')"/>
          </v-layout>
          <v-layout> 
            <va-input 
              v-model="item.threshold"
              v-validate="item.account_type == 'Deduction'? 'required|not_negative' : ''" 
              data-vv-scope="account"
              data-vv-as="threshold"
              v-money="money"
              ref="threshold"
              placeholder=" "
              label="Threshold/Free Pay amount"
              :error-messages="errors.collect('account.threshold')"
              data-vv-name="threshold"/>

            <va-select
              class="ml-3" 
              v-model="item.threshold_usage"
              v-validate="item.account_type == 'Deduction'? 'required' : ''" 
              data-vv-scope="account"
              data-vv-as="threshold usage"
              placeholder=" "
              :width="'340px'"
              label="Threshold condition"
              :error-messages="errors.collect('account.threshold_usage')"
              :items="['Use Threshold if Taxable Income is Greater', 'Use Threshold if Taxable Income is Less', 'Use Threshold as a Deductible']"
              data-vv-name="threshold_usage"/>

          </v-layout>

          <v-select  multiple v-model="item.dependencies" placeholder=" " label="Less (Optional)" :items="dependables"/>
        </div>
      
        <v-checkbox v-if=" item.account_type === 'Income'" v-model="item.taxable" label="Taxable"></v-checkbox>

      </v-card-text>

      <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
        <v-spacer></v-spacer>
        <v-btn flat @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submit">{{item.ID ? 'Update' : 'Save'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-card>
    <v-toolbar dense color="white" class="pa-0">
      <v-text-field
        v-model="search"
        solo
        label="Search"
        prepend-inner-icon="search"
      ></v-text-field>

      <v-divider class="mr-2" vertical></v-divider>

      <v-btn color="primary"  @click="openModal" dark class="mb-2">New Account Type</v-btn>
    </v-toolbar>
    
    <v-tabs v-model="tabModel"  color=" elevation-1"  centered="" class="mb-3"  slider-color="blue">
      <v-tab :href="`#tab-income`">Income Types</v-tab>
      <v-tab :href="`#tab-deduction`">Deduction Types</v-tab>
      <v-tab :href="`#tab-expense`">Expense Types</v-tab>
      <v-tab :href="`#tab-asset`">Assets Types</v-tab>
      <v-tab :href="`#tab-liability`">Liabilities Types</v-tab>
    </v-tabs>
    <v-tabs-items  v-model="tabModel">
      <v-tab-item :value="'tab-income'">
        <v-data-table 
          hide-actions
          :headers="incomeTblHeaders" 
          :items="items.filter((item) => item.account_type === 'Income' && item.account_name.toLowerCase().indexOf(search.toLowerCase()) != -1)" class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.account_name | capitalize }}</td>
            <td class="text-xs-left">{{ (props.item.taxable || 'False') | capitalize }}</td>
            
            <td >
              <v-icon small class="mr-2" @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="'tab-deduction'">
        <v-data-table 
          hide-actions
          :headers="deductionTblHeaders" 
          :items="items.filter((item) => item.account_type === 'Deduction' && item.account_name.toLowerCase().indexOf(search.toLowerCase()) != -1)" class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.account_name | capitalize  }}</td>  
            <td class="text-xs-left">{{ props.item.deduction_type | capitalize }}</td>
            <td class="text-xs-left">{{ '$ '+props.item.threshold }}</td>
            <td class="text-xs-left">{{ props.item.rate + ' %'}}</td>
            
            <td >
              <v-icon small class="mr-2" @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="'tab-expense'">
        <v-data-table 
          hide-actions
          :headers="expenseTblHeaders" 
          :items="items.filter((item) => item.account_type === 'Expense' && item.account_name.toLowerCase().indexOf(search.toLowerCase()) != -1)" class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.account_name | capitalize  }}</td>
            
            <td >
              <v-icon small class="mr-2" @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="'tab-asset'">
        <v-data-table 
          hide-actions
          :headers="assetTblHeaders" 
          :items="items.filter((item) => item.account_type === 'Asset' && item.account_name.toLowerCase().indexOf(search.toLowerCase()) != -1)" class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.account_name | capitalize  }}</td>
            
            <td >
              <v-icon small class="mr-2" @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="'tab-liability'">
        <v-data-table 
          hide-actions
          :headers="liabilityTblHeaders"
          :items="items.filter((item) => item.account_type === 'Liability' && item.account_name.toLowerCase().indexOf(search.toLowerCase()) != -1)" class="elevation-1">
          <template slot="items" slot-scope="props">
            <td class="text-xs-left">{{ props.item.account_name | capitalize  }}</td>
            
            <td >
              <v-icon small class="mr-2" @click="edit(props.item)">
                edit
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

  </v-card>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapMutations } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import ratioService from '@/services/ratio-service'
import validations from '@/common/validator'
export default {
  components: {
    VaInput,
    VaSelect
  },

  created () {
    this.initialize()
  },
  
  data() {
    return {
      modal: false,
      disableType: false,
      search: '',
      tabModel:'tab-income',
      money: {
          decimal: '.',
          thousands: ',',
          prefix: '',
          precision: 2,
          masked: true
      },
      incomeTblHeaders: [
        { text: 'Income Type', align: 'left', sortable: false, value: 'account_name'},
        { text: 'Taxable', align: 'left', sortable: false, value: 'taxable'},
        
        { text: 'Action', align: 'left', sortable: false, },
      ],
      deductionTblHeaders: [
        { text: 'Deduction', align: 'left', sortable: false, value: 'account_name'},
        { text: 'Deduction Type', align: 'left', sortable: false, value: 'deduction_type'},
        { text: 'Threshold', align: 'left', sortable: false, value: 'threshold'},
        { text: 'Rate', align: 'left', sortable: false, value: 'rate'},
        
        { text: 'Action', align: 'left', sortable: false, },
      ],
      expenseTblHeaders: [
        { text: 'Expense', align: 'left', sortable: false, value: 'account_name'},
        
        { text: 'Action', align: 'left', sortable: false, },
      ],
      assetTblHeaders: [
        { text: 'Asset', align: 'left', sortable: false, value: 'account_name'},
        
        { text: 'Action', align: 'left', sortable: false, },
      ],
      liabilityTblHeaders: [
        { text: 'Liability', align: 'left', sortable: false, value: 'account_name'},
        
        { text: 'Action', align: 'left', sortable: false, },
      ],
      accountTypes: ['Expense', 'Deduction', 'Income', 'Asset', 'Liability'],
      defaultItem: {
        account_name     : '',
        account_type     : '',
        taxable          : false,
        deduction_type   : '',
        dependable       : false,
        dependencies     : [],
        rate             : 0,
        threshold        : 0,
        threshold_usage   : '',
        is_flat: false
      },
      dependables: [],
      item: {},
      items: [],
    }
  },

  computed: {
    ...mapState([
      'appState',
      'loanItemState',
      'deleteLoanItem'
    ]), 
    
    formTitle () {
      return this.item.ID ? 'Edit Account Type' : 'New Account Type'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    openModal() {
      this.loadDependables()

      this.disableType = false
      this.item = {...this.defaultItem}
      this.modal = true
    },

    loadDependables() {
      if (this.item.ID && this.item.account_type == "Deduction") {
        this.dependables = this.items
          .filter( el => el.dependable && el.account_type == 'Deduction' && el.account_name.toLowerCase() !== this.item.account_name.toLowerCase() )
          .filter((el) => { 
            let dep = []
            if(el.dependencies)
              dep = el.dependencies.filter((elx) => elx === this.item.account_name)
              
            return dep.length == 0
           })
          .map((el) =>  el.account_name )
      } else {
        this.dependables = this.items
          .filter( el => el.dependable && el.account_type == 'Deduction' )
          .map( el => el.account_name )
      }
    },

    closeModal() {
      this.item = {...this.defaultItem}
      this.modal = false
    },

    edit(item) {
      this.disableType = true
      this.item = {...item}

      this.loadDependables()
      if(this.$refs.threshold)
        this.$refs.threshold.$el.getElementsByTagName('input')[0].value = Number(item.threshold).toPrecision(2)
      
      this.modal = true
    },

    async initialize() {
      this.$validator.extend(validations.notNegative.name, validations.notNegative.validator)
      await this.fetchData()
    },

    async deleteItem(item) {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      this.$toast.question('Are you sure you want to delete this account type?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              await ratioService.delete(item.ID)

              let len = this.items.length
              for(let i = 0; i < len; i++) {
                if(item.ID == this.items[i].ID) 
                  this.items.splice(i,1)
              }

              this.$toast.warning('Record has been deleted!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('deleting: ',e)
              if(e.response) {
                if(e.response.status === 404)
                  this.$toast.error("Account has been already deleted", '',{position: 'center'})
                else
                  this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              }
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    async submit() {
      let isValid = await this.$validator.validateAll("account")
      if(isValid) {
        this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },

    async save () {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)

        if(this.item.threshold)
          this.item.threshold = Number(this.item.threshold.toString().split(',').join('')).toFixed(2)

        let data = {...this.item}

        data.dependencies = JSON.stringify(this.item.dependencies)

        if (this.item.ID) {
          delete data['date_created']
          delete data['date_updated']

          await ratioService.update(this.item.ID, data)
          let len = this.items.length
          for(let i = 0; i < len; i++) {
            if(this.item.ID == this.items[i].ID) 
              this.items.splice(i, 1, {...this.item})
          }
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          let response = await ratioService.create(data)
          this.item.ID = response.data
          this.items.push(this.item)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.closeModal()
       
      } catch(e) {
        console.log('saving/updating: ',e)
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      }
    },

    async fetchData() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let response = await ratioService.findAll() 
        this.items = response.data.map((el)=>{
          if(typeof el.dependencies === 'string') {
            el.dependencies = el.dependencies.replace(/'/g, '"')
            el.dependencies = JSON.parse(el.dependencies)
          }

          return el
        })
        
      } catch(e) {
        console.log('fetching: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },
  },

}
</script>

 
