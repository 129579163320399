<template>
  <div class="pa-3">
    <v-dialog persistent v-model="customerSearchDialog" width="600px">
      <v-card class="pr-4 pl-4 pt-4 pb-2" color="white">
        <p class="subheading">Search for an existing customer</p>
        <v-autocomplete
          v-model="searchCustomerModel"
          :items="customers"
          :loading="isLoading"
          chips
          @input.native="onFindCustomer($event)"
          @change="onSelectCreated($event)"
          clearable
          item-text="trn"
          item-value="ID"
          ref="searchCustomerRef"
          solo
        >
          <template v-slot:selection="{ item, selected }">{{
            item.first_name + " " + item.last_name + " - " + (item.trn || "N/A")
          }}</template>
          <template v-slot:item="{ item }">
            <v-list-tile-avatar>
              <img
                :src="item.avatar || require('@/assets/img/avatar.png')"
                style="border-radius: 50%; width 36px; height: 36px"
              />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title
                v-text="item.first_name + ' ' + item.last_name"
              ></v-list-tile-title>
              <v-list-tile-sub-title
                v-text="item.trn || 'N/A'"
              ></v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon>fa-user</v-icon>
            </v-list-tile-action>
          </template>
        </v-autocomplete>
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn
            color="primary"
            :loading="isLoading"
            @click="
              customerSearchDialog = false;
              searchCustomerDB = '';
              customers = [];
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Business Search Dialog -->
    <v-dialog persistent v-model="businessSearchDialog" width="600px">
      <v-card class="pr-4 pl-4 pt-4 pb-2" color="white">
        <p class="subheading">Search for an existing business</p>
        <v-autocomplete
          v-model="searchBusinessModel"
          :items="customers"
          :loading="isLoading"
          chips
          @input.native="onFindCustomer($event)"
          @change="onSelectCreated($event)"
          clearable
          item-text="business_name"
          item-value="ID"
          :label="`Search for a business...`"
          ref="searchBusinessRef"
          solo
        >
          <template v-slot:selection="{ item, selected }">{{
            item.business_name +
            " " +
            item.business_type +
            " - " +
            (item.trn || "N/A")
          }}</template>
          <template v-slot:item="{ item }">
            <v-list-tile-avatar>
              <img
                :src="item.logo || require('@/assets/img/logo-here.png')"
                style="border-radius: 50%; width 36px; height: 36px"
              />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title
                v-text="item.business_name + ' / ' + item.business_type"
              ></v-list-tile-title>
              <v-list-tile-sub-title
                v-text="item.trn || 'N/A'"
              ></v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn
            color="primary"
            :loading="isLoading"
            @click="
              businessSearchDialog = false;
              searchBusinessDB = '';
              customers = [];
            "
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <customer-form
      :customer="customer"
      :youth="isYouth"
      @gotoprofile="showCustomerProfile($event)"
      :opened.sync="customerDialog"
      @created="onCustomerCreated($event)"
    ></customer-form>

    <business-form
      :opened.sync="businessDialog"
      @created="onBusinessCreated($event)"
    ></business-form>

    <v-dialog persistent fullscreen v-model="formOpened">
      <v-card>
        <v-toolbar dense dark color="primary darken-3">
          <v-btn
            icon
            dark
            v-if="selectedOption !== ''"
            @click="onBackBtnClicked"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              selectedOption === ""
                ? "Account Creation Options"
                : (account.ID ? "Edit " : "Create ") +
                  `${
                    selectedOption.charAt(0).toUpperCase() +
                    selectedOption.slice(1)
                  } Account Creation`
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeForm">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container
          class="pt-4"
          v-if="selectedOption === ''"
          fluid
          grid-list-lg
        >
          <v-layout row wrap justify-center aligned-center fill-height pt-5>
            <v-flex xs12 md4>
              <v-card dark color="primary" hover>
                <v-card-title primary-title>
                  <div>
                    <div class="headline font-weight-bold mb-1">
                      New Youth Account
                    </div>
                    <span class="font-weight-light"
                      >Create a personal youth account.</span
                    >
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn flat @click="youthSearchDialog = true"
                    >Click here to proceed</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-flex>
            <v-flex xs12 md4>
              <v-card dark color="primary darken-1" hover class>
                <v-card-title primary-title>
                  <div>
                    <div class="headline font-weight-bold mb-1">
                      New Personal Account
                    </div>
                    <span class="font-weight-light"
                      >Create a personal account.</span
                    >
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn flat @click="chooseType('personal')"
                    >Click here to proceed</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-flex>
            <v-flex xs12 md4>
              <v-card dark color="primary darken-2" hover>
                <v-card-title primary-title>
                  <div>
                    <div class="headline font-weight-bold mb-1">
                      New Business Account
                    </div>
                    <span class="font-weight-light"
                      >Create a business account.</span
                    >
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn flat @click="chooseType('business')"
                    >Click here to proceed</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>

        <!-- Youth Search Dialog -->
        <v-dialog persistent v-model="youthSearchDialog" width="400px">
          <v-toolbar dense dark color="primary">
            <v-btn
              icon
              dark
              v-if="youthSearchActivated"
              @click="
                youthSearchActivated = false;
                youthSearchDialog == [];
              "
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{ "Find Youth" }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="
                youthSearchDialog = false;
                youth = {};
                youthSearchList = [];
                youthSearchActivated = false;
              "
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card class="pr-4 pl-4 pt-4 pb-2" color="white">
            <div v-show="youthSearchList.length == 0 && !youthSearchActivated">
              <va-input
                v-model="youth.first_name"
                v-validate="'required|min:2|max:100'"
                data-vv-scope="youth"
                data-vv-validate-on="blur"
                data-vv-as="first name"
                label="First Name"
                data-vv-name="first_name"
                placeholder=" "
                :error-messages="errors.collect('youth.first_name')"
              />
              <va-input
                v-model="youth.last_name"
                v-validate="'required|min:3|max:100'"
                data-vv-scope="youth"
                data-vv-validate-on="blur"
                label="Last Name"
                data-vv-name="last_name"
                data-vv-as="last name"
                placeholder=" "
                :error-messages="errors.collect('youth.last_name')"
              />
              <va-input
                v-model="youth.date_of_birth"
                v-validate="
                  'required|date_format:YYYY-MM-DD|not_greater_than_today|date_less_than: ' +
                  accountSettings.youth_max_age
                "
                data-vv-scope="youth"
                data-vv-as="date of birth"
                :error-messages="errors.collect('youth.date_of_birth')"
                placeholder=" "
                return-masked-value
                mask="####-##-##"
                label="Date of Birth (yyyy-mm-dd)"
                data-vv-validate-on="blur"
                data-vv-name="date_of_birth"
              />

              <va-input
                v-model="youth.guardian_last_name"
                v-validate="'required'"
                data-vv-scope="youth"
                data-vv-validate-on="blur"
                label="Mother/Guardian Last Name"
                data-vv-name="guardian_name"
                data-vv-as="guardian name"
                placeholder=" "
                :error-messages="errors.collect('youth.guardian_name')"
              />
              <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn color="primary" :loading="isLoading" @click="findYouth"
                  >Search</v-btn
                >
              </v-card-actions>
            </div>

            <!-- Search Results -->
            <v-card
              class="pa-4"
              style="text-align: center"
              v-for="obj in youthSearchList"
              :key="obj.ID"
            >
              <h2>{{ obj.first_name + " " + obj.last_name }}</h2>
              <p>
                <b>Date of Birth:</b>
                {{ obj.date_of_birth }}
              </p>
              <p>
                <b>Address:</b>
                {{
                  obj.living_address.street +
                  ", " +
                  obj.living_address.city +
                  ", " +
                  obj.living_address.province +
                  ", " +
                  obj.living_address.country
                }}
              </p>
              <v-btn block color="primary" @click="startYouthAccount(obj)"
                >Start Account Creation</v-btn
              >
            </v-card>
            <v-card
              v-if="youthSearchList.length == 0 && youthSearchActivated == true"
              class="pa-4"
            >
              <h2 style="text-align: center">
                Did not find any matching record
              </h2>
              <v-btn block color="primary" @click="openedYouthDIalog"
                >Add Youth</v-btn
              >
            </v-card>
          </v-card>
        </v-dialog>

        <!-- Account Creation -->
        <v-container
          class="pt-4"
          v-if="selectedOption !== ''"
          fluid
          grid-list-lg
        >
          <v-alert
            :value="messageObj.message !== ''"
            style="margin-top: -10px"
            dismissible
            :type="messageObj.type"
            >{{ messageObj.message }}</v-alert
          >
          <v-layout>
            <v-flex class="pa-3" xs3>
              <h2 class="mb-4">Account Details</h2>
              <v-layout row wrap>
                <v-flex class="pt-0 pb-0" xs12>
                  <va-input
                    v-model="account.main_account_number"
                    data-vv-scope="account"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('account.main_account_number')
                    "
                    data-vv-validate-on="blur"
                    data-vv-as="account number"
                    data-vv-name="main_account_number"
                    width="300px"
                    placeholder=" "
                    label="Main Account Number "
                  />
                </v-flex>
                <v-flex class="pt-0 pb-0" xs12>
                  <va-select
                    :items="branches"
                    :item-text="'branch_name'"
                    :item-value="'ID'"
                    width="300px"
                    v-model="account.branch_id"
                    label="Account Branch"
                    placeholder="--Select Branch--"
                    :error-messages="errors.collect('account.branch')"
                    v-validate="'required'"
                    data-vv-scope="account"
                    data-vv-validate-on="blur"
                    data-vv-as="branch"
                    data-vv-name="branch"
                  />
                </v-flex>
                <v-flex class="pt-0 pb-0" xs12>
                  <va-input
                    v-validate="'required|date_format:YYYY-MM-DD'"
                    v-model="account.account_opening_date"
                    width="300px"
                    return-masked-value
                    mask="####-##-##"
                    placeholder=" "
                    data-vv-scope="account"
                    :data-vv-name="'opening_date'"
                    data-vv-as="opening date"
                    :error-messages="errors.first('account.opening_date')"
                    label="Account Opening Date (yyyy-mm-dd)"
                    data-vv-validate-on="blur"
                  />
                </v-flex>
                <v-flex class="pt-0 pb-0" xs12>
                  <label for style="font-size: 12px">Total Balance</label>
                  <money
                    v-model="totalBalance"
                    style="margin-top: 2px; width: 300px"
                    disabled
                    readonly
                    data-vv-as="amount"
                    data-vv-name="amount"
                    data-vv-scope="loan"
                    single-line
                    v-bind="money"
                    bottom
                  ></money>
                  <p class="error-message">{{ errors.first("loan.amount") }}</p>
                </v-flex>

                <v-btn
                  block
                  dark
                  color="primary"
                  :loading="isLoading"
                  :disabled="isLoading"
                  v-if="selectedOption !== ''"
                  @click="submit"
                >
                  {{ this.account.ID ? "Update" : "Save" }}
                </v-btn>
              </v-layout>
            </v-flex>

            <v-flex xs9>
              <v-tabs
                v-model="tabModel"
                class="mb-3 elevation-2"
                slider-color="blue"
              >
                <v-tab :href="`#tab-sub`">Sub Accounts</v-tab>
                <v-tab :href="`#tab-details`">Account Holders</v-tab>
                <v-tab :href="`#tab-authorizee`">Authorisee</v-tab>
                <v-tab :href="`#tab-beneficiary`">Beneficiaries</v-tab>
                <v-tab :href="`#tab-trend`">Trends</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabModel">
                <v-tab-item :value="`tab-sub`" class="pa-4">
                  <v-toolbar dense color="white" class="pa-0 elevation-3">
                    <span class="title mr-3 text--grey text--darken-3"
                      >Sub Accounts</span
                    >
                    <v-text-field
                      v-model="subAccountSearch"
                      solo
                      label="Search"
                      prepend-inner-icon="search"
                    ></v-text-field>

                    <v-divider class="mr-2" vertical></v-divider>

                    <v-btn
                      @click="addSubAccount"
                      color="primary darken-1"
                      dark
                      class="mb-2"
                      >Add New</v-btn
                    >
                  </v-toolbar>
                  <v-data-table
                    hide-actions
                    :search="subAccountSearch"
                    :headers="subAccountHeaders"
                    :items="account.sub_accounts"
                    :loading="isLoading"
                    class="elevation-1"
                  >
                    <v-progress-linear
                      slot="progress"
                      color="blue"
                      indeterminate
                    ></v-progress-linear>
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.sub_account_number }}</td>
                      <td class="text-xs-left">
                        <va-select
                          data-vv-scope="account"
                          v-model="props.item.sub_account_type_id"
                          :items="subAccountTypes"
                          :item-text="'sub_account_type_name'"
                          :item-value="'ID'"
                          placeholder="--Select Account Type--"
                          @change="onSubAccountChanged($event, props.index)"
                          single-line
                        />
                      </td>
                      <td class="text-xs-left">
                        <va-select
                          v-validate="'required'"
                          :items="
                            accountDataSetForSubAccounts[props.index]
                              .account_definitions
                          "
                          :item-text="'account_name'"
                          :item-value="'ID'"
                          v-model="props.item.ID"
                          placeholder="Select Sub Account"
                          data-vv-scope="sub"
                          @change="setSubAccountNumber($event, props.index)"
                          :data-vv-name="'ID' + props.index"
                          data-vv-as="sub account"
                          :error-messages="errors.first('sub.ID' + props.index)"
                          single-line
                        ></va-select>
                      </td>
                      <td class="text-xs-left">
                        <money
                          class="mt-3"
                          v-validate="'required|decimal|not_negative'"
                          v-model.lazy="props.item.account_balance"
                          :data-vv-name="'amount' + props.index"
                          data-vv-as="account balance"
                          data-vv-scope="sub"
                          single-line
                          v-bind="money"
                          bottom
                        ></money>
                        <p class="error-message">
                          {{ errors.first("sub.amount" + props.index) }}
                        </p>
                      </td>
                      <td class="text-xs-left">
                        <b>{{ props.item.account_status || "New" }}</b>
                      </td>
                      <td>
                        <v-icon
                          small
                          class="mr-2"
                          @click="removeSubAccount(props.index)"
                          >remove</v-icon
                        >
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item :value="`tab-details`" class="pa-4">
                  <v-toolbar dense color="white" class="pa-0 elevation-3">
                    <span class="title mr-3 text--grey text--darken-3">
                      Account Holder
                      <small>(s)</small>
                    </span>
                    <v-text-field
                      v-model="accountHolderSearch"
                      solo
                      label="Search"
                      prepend-inner-icon="search"
                    ></v-text-field>

                    <v-divider class="mr-2" vertical></v-divider>

                    <div
                      v-if="
                        selectedOption === 'personal' ||
                        (selectedOption === 'business' &&
                          account.account_holders.length === 0)
                      "
                    >
                      <v-btn
                        @click="
                          selectedOption === 'business'
                            ? addExistingBusiness()
                            : addExisting('account_holders')
                        "
                        color="primary darken-1"
                        dark
                        class="mb-2"
                        >Add Existing
                        {{
                          selectedOption === "business"
                            ? "Business"
                            : "Customer"
                        }}</v-btn
                      >
                      <v-btn
                        @click="
                          selectedOption === 'business'
                            ? addBusiness()
                            : addCustomer('account_holders')
                        "
                        color="primary darken-1"
                        dark
                        class="mb-2"
                        >Add New
                        {{
                          selectedOption === "business"
                            ? "Business"
                            : "Customer"
                        }}</v-btn
                      >
                    </div>
                  </v-toolbar>
                  <v-data-table
                    hide-actions
                    :search="accountHolderSearch"
                    :headers="
                      selectedOption === 'business'
                        ? busninessSccountHolderHeaders
                        : accountHolderHeaders
                    "
                    :items="accountHolders"
                    :loading="isLoading"
                    class="elevation-1"
                  >
                    <v-progress-linear
                      slot="progress"
                      color="blue"
                      indeterminate
                    ></v-progress-linear>
                    <template slot="items" slot-scope="props">
                      <tr v-if="selectedOption === 'business'">
                        <td>{{ props.item.trn || "N/A" }}</td>
                        <td>{{ props.item.business_name }}</td>
                        <td>{{ props.item.business_type }}</td>
                        <td>
                          {{
                            props.item.living_address.street +
                            "," +
                            props.item.living_address.city +
                            ", " +
                            props.item.living_address.province +
                            ", " +
                            props.item.living_address.country
                          }}
                        </td>
                        <td>
                          <v-icon
                            small
                            class="mr-2"
                            @click="removeAccountHolder(props.index)"
                            >remove</v-icon
                          >
                        </td>
                      </tr>
                      <tr v-else>
                        <td>{{ props.item.trn || "N/A" }}</td>
                        <td>
                          {{
                            props.item.first_name + " " + props.item.last_name
                          }}
                        </td>
                        <td>{{ props.item.date_of_birth }}</td>
                        <td>
                          {{
                            props.item.living_address.street +
                            "," +
                            props.item.living_address.city +
                            ", " +
                            props.item.living_address.province +
                            ", " +
                            props.item.living_address.country
                          }}
                        </td>
                        <td>
                          <v-icon
                            small
                            class="mr-2"
                            v-if="selectedOption !== 'youth'"
                            @click="removeAccountHolder(props.index)"
                            >remove</v-icon
                          >
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item :value="`tab-authorizee`" class="pa-4">
                  <v-toolbar dense color="white" class="pa-0 elevation-3">
                    <span class="title mr-3 text--grey text--darken-3"
                      >Authorisee</span
                    >
                    <v-text-field
                      solo
                      label="Search"
                      prepend-inner-icon="search"
                    ></v-text-field>

                    <v-divider class="mr-2" vertical></v-divider>
                    <v-btn
                      @click="addExisting('authorisees')"
                      color="primary darken-1"
                      dark
                      class="mb-2"
                      >Add Existing</v-btn
                    >
                    <v-btn
                      @click="addCustomer('authorisees')"
                      color="primary darken-1"
                      dark
                      class="mb-2"
                      >Add</v-btn
                    >
                  </v-toolbar>
                  <v-data-table
                    hide-actions
                    :search="accountHolderSearch"
                    :headers="accountHolderHeaders"
                    :items="account.authorisees"
                    :loading="isLoading"
                    class="elevation-1"
                  >
                    <v-progress-linear
                      slot="progress"
                      color="blue"
                      indeterminate
                    ></v-progress-linear>
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.trn }}</td>
                      <td>
                        {{ props.item.first_name + " " + props.item.last_name }}
                      </td>
                      <td>{{ props.item.date_of_birth }}</td>
                      <td>
                        {{
                          props.item.living_address.street +
                          "," +
                          props.item.living_address.city +
                          ", " +
                          props.item.living_address.province +
                          ", " +
                          props.item.living_address.country
                        }}
                      </td>
                      <td>
                        <v-icon
                          v-if="!props.item.is_owner"
                          small
                          class="mr-2"
                          @click="removeAuthorisee(props.index)"
                          >remove</v-icon
                        >
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item :value="`tab-beneficiary`" class="pa-4">
                  <v-toolbar dense color="white" class="pa-0 elevation-3">
                    <span class="title mr-3 text--grey text--darken-3"
                      >Beneficiaries</span
                    >
                    <v-text-field
                      solo
                      label="Search"
                      prepend-inner-icon="search"
                    ></v-text-field>

                    <v-divider class="mr-2" vertical></v-divider>
                    <v-btn
                      @click="addBeneficiary"
                      color="primary darken-1"
                      dark
                      class="mb-2"
                      >Add</v-btn
                    >
                  </v-toolbar>
                  <v-data-table
                    hide-actions
                    :headers="beneficiariesHeaders"
                    :items="account.beneficiaries"
                    :loading="isLoading"
                    class="elevation-1"
                  >
                    <v-progress-linear
                      slot="progress"
                      color="blue"
                      indeterminate
                    ></v-progress-linear>
                    <template slot="items" slot-scope="props">
                      <td>
                        <va-input
                          v-model="props.item.name"
                          placeholder="Name"
                          single-line
                          data-vv-scope="ben"
                          v-validate="'required'"
                          :data-vv-name="'name' + props.index"
                          data-vv-as="name"
                          :error-messages="
                            errors.first('ben.name' + props.index)
                          "
                        />
                      </td>
                      <td>
                        <va-select
                          :items="relations"
                          v-model="props.item.relation"
                          placeholder="Select Relation"
                          single-line
                          data-vv-scope="ben"
                          v-validate="'required'"
                          :data-vv-name="'relations' + props.index"
                          data-vv-as="relation"
                          :error-messages="
                            errors.first('ben.relations' + props.index)
                          "
                        ></va-select>
                      </td>
                      <td>
                        <va-input
                          v-model="props.item.date_of_birth"
                          v-validate="'required|date_format:YYYY-MM-DD'"
                          data-vv-as="date of birth"
                          placeholder="yyyy-mm-dd"
                          return-masked-value
                          mask="####-##-##"
                          label=" "
                          single-line
                          data-vv-scope="ben"
                          :data-vv-name="'date' + props.index"
                          :error-messages="
                            errors.first('ben.date' + props.index)
                          "
                        />
                      </td>
                      <td>
                        <va-input
                          v-model="props.item.percentage_alloted"
                          placeholder="Percentage Alloted"
                          single-line
                          data-vv-scope="ben"
                          v-validate="
                            'required|decimal|greater_than:0|max_value:100'
                          "
                          :data-vv-name="'Percentage_alloted' + props.index"
                          data-vv-as="percentage alloted"
                          :error-messages="
                            errors.first('ben.Percentage_alloted' + props.index)
                          "
                        />
                      </td>
                      <td>
                        <v-icon
                          small
                          class="mr-2"
                          @click="removeBeneficiary(props.index)"
                          >delete</v-icon
                        >
                      </td>
                    </template>
                    <template slot="footer">
                      <td class="text-xs-right" colspan="3">
                        <strong>Total Percentage Alloted</strong>
                      </td>
                      <td class="text-xs-left" colspan="2">
                        {{ totalBeneficaryPercentage + "%" }}
                      </td>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item class="pa-4" :value="`tab-trend`">
                  <h2 class="mb-4">Account Trends</h2>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-flex class="pt-0 pb-0" xs12>
                        <label for style="font-size: 12px"
                          >Average Cash Deposit Amount</label
                        >
                        <money
                          style="margin-top: 2px; width: 300px"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="account.average_cash_deposit_amount"
                          data-vv-as="average cash deposit amount"
                          data-vv-name="average_cash_deposit_amount"
                          data-vv-validate-on="blur"
                          data-vv-scope="trend"
                          placeholder=" "
                          v-bind="money"
                        ></money>
                        <p class="error-message">
                          {{
                            errors.first("trend.average_cash_deposit_amount")
                          }}
                        </p>
                      </v-flex>
                      <v-flex class="pt-0 pb-0" xs12>
                        <label for style="font-size: 12px"
                          >Average Cheque Deposit Amount</label
                        >
                        <money
                          style="margin-top: 2px; width: 300px"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="account.average_cheque_deposit_amount"
                          data-vv-as="average cheque deposit amount"
                          data-vv-name="average_cheque_deposit_amount"
                          data-vv-validate-on="blur"
                          data-vv-scope="trend"
                          placeholder=" "
                          v-bind="money"
                        ></money>
                        <p class="error-message">
                          {{
                            errors.first("trend.average_cheque_deposit_amount")
                          }}
                        </p>
                      </v-flex>
                      <v-flex class="pt-0 pb-0" xs12>
                        <label for style="font-size: 12px"
                          >Average Internal Transfer Deposit Amount</label
                        >
                        <money
                          style="margin-top: 2px; width: 300px"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="account.average_internal_transfer_deposits"
                          data-vv-as="average internal transfer deposits"
                          data-vv-name="average_internal_transfer_deposits"
                          data-vv-validate-on="blur"
                          data-vv-scope="trend"
                          placeholder=" "
                          v-bind="money"
                        ></money>
                        <p class="error-message">
                          {{
                            errors.first(
                              "trend.average_internal_transfer_deposits"
                            )
                          }}
                        </p>
                      </v-flex>
                    </v-flex>
                    <v-flex xs6>
                      <v-flex class="pt-0 pb-0" xs12>
                        <va-input
                          style="width: 300px"
                          v-validate="'required|numeric|min_value:0'"
                          v-model="account.average_number_of_deposits"
                          data-vv-as="average number of deposits"
                          data-vv-name="average_number_of_deposits"
                          data-vv-validate-on="blur"
                          data-vv-scope="trend"
                          placeholder=" "
                          label="Average Number of Deposits"
                          :error-messages="
                            errors.first('trend.average_number_of_deposits')
                          "
                        />
                      </v-flex>
                      <v-flex class="pt-0 pb-0" xs12>
                        <label for style="font-size: 12px"
                          >Average Wire Transfers Amount</label
                        >
                        <money
                          style="margin-top: 2px; width: 300px"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="account.average_wire_transfers_amount"
                          data-vv-as="average wire transfers amount"
                          data-vv-name="average_wire_transfers_amount"
                          data-vv-validate-on="blur"
                          data-vv-scope="trend"
                          placeholder=" "
                          v-bind="money"
                        ></money>
                        <p class="error-message">
                          {{
                            errors.first("trend.average_wire_transfers_amount")
                          }}
                        </p>
                      </v-flex>
                      <v-flex class="pt-0 pb-0" xs12>
                        <label for style="font-size: 12px"
                          >Average Withdrawal Amount</label
                        >
                        <money
                          style="margin-top: 2px; width: 300px"
                          v-validate="'required|decimal|min_value:0'"
                          v-model="account.average_withdrawal_amount"
                          data-vv-as="average withdrawal amount"
                          data-vv-validate-on="blur"
                          data-vv-name="average_withdrawal_amount"
                          data-vv-scope="trend"
                          placeholder=" "
                          v-bind="money"
                        ></money>
                        <p class="error-message">
                          {{ errors.first("trend.average_withdrawal_amount") }}
                        </p>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs-items>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<style type="css" scoped>
h1,
h2,
h3 {
  color: #505a6b;
}

label {
  color: #505a6b;
}

.active {
  background: #eee;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import validations from "@/common/validator";
import CustomerForm from "@/views/apps/customer-management/CustomerForm";
import BusinessForm from "@/views/apps/customer-management/BusinessForm";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
import { Money } from "v-money";
import _ from "lodash";
import moment from "moment";

import accountSettingsService from "@/services/account-settings-service";
import settingsService from "@/services/general-settings-service";

import accountService from "@/services/account-service";
import customerService from "@/services/customer-service";
import BranchService from "@/services/branch-service";
import storageService from "@/services/storage-service";
export default {
  components: {
    CustomerForm,
    BusinessForm,
    VaSelect,
    VaInput,
    Money,
  },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    accountProp: {
      type: Object,
      default: {
        account_holders: [],
        sub_accounts: [],
        authorisees: [],
        beneficiaries: [],
      },
    },
  },

  created() {
    this.initialize();
  },

  data() {
    return {
      isLoading: false,
      isYouth: false,
      messageObj: { type: "error", message: "" },
      tabModel: "tab-sub",
      businessDialog: false,
      customerDialog: false,
      customerSearchDialog: false,
      customerSearchFunc: null,
      searchCustomerDB: "",
      searchCustomerModel: "",

      businessSearchDialog: false,
      searchBusinessDB: "",
      searchBusinessModel: "",

      customers: [],
      customer: {
        contact_info: {},
        living_address: { type: "Living" },
        mailing_address: { type: "Mailing" },
        employment_info: {},
        school_info: {},
        identifications: [],
        spouse: {},
        nearest_contact: {},
      },
      business: {},
      selectedOption: "",
      accountOptionsDialog: false,
      subAccountSearch: "",
      accountHolderSearch: "",
      accountHolders: [],
      totalBalance: 0.0,
      totalBeneficaryPercentage: 0.0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },

      account: {
        branch_id: "",
        main_account_number: "",
        external_account_number: "",
        account_status: "",
        account_opening_date: "",
        account_last_transaction_date: "",
        average_cash_deposit_amount: 0,
        average_cheque_deposit_amount: 0,
        average_internal_transfer_deposits: 0,
        average_number_of_deposits: 0,
        average_wire_transfers_amount: 0,
        average_withdrawal_amount: 0,
        account_holders: [],
        sub_accounts: [],
        authorisees: [],
        beneficiaries: [],
      },

      youth: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        guardian_last_name: "",
      },
      youthSearchList: [],
      youthSearchDialog: false,
      youthSearchActivated: false,

      branches: [],
      subAccountTypes: [],
      accountTypes: [],
      relations: [
        "Spouse",
        "Child",
        "Mother",
        "Father",
        "Sibling",
        "Friend",
        "Relative",
      ],

      accountDataSetForSubAccounts: [],

      busninessSccountHolderHeaders: [
        { text: "Tax Number", align: "left", sortable: false },
        { text: "Business Name", align: "left", sortable: false },
        { text: "Business Type", align: "left", sortable: false },
        { text: "Address", align: "left", sortable: false },
        { text: "Action", align: "left", sortable: false },
      ],

      accountHolderHeaders: [
        { text: "Tax Number", align: "left", sortable: false },
        { text: "Name", align: "left", sortable: false },
        { text: "Date of Birth", align: "left", sortable: false },
        { text: "Address", align: "left", sortable: false },
        { text: "Action", align: "left", sortable: false },
      ],

      beneficiariesHeaders: [
        { text: "Name", align: "left", sortable: false },
        { text: "Relation", align: "left", sortable: false },
        { text: "Date of Birth", align: "left", sortable: false },
        { text: "Percentage Alloted", align: "left", sortable: false },
        { text: "Action", align: "left", sortable: false },
      ],

      subAccountHeaders: [
        { text: "#", align: "left", sortable: false },
        { text: "Account Type", align: "left", sortable: false },
        { text: "Sub Account", align: "left", sortable: false },
        { text: "Balance", align: "left", sortable: false },
        { text: "Status", align: "left", sortable: false },
        { text: "Action", align: "left", sortable: false },
      ],

      accountSettings: {},
    };
  },

  computed: {
    ...mapState(["appState"]),

    formOpened: {
      get: function () {
        if (this.opened) {
          this.account.account_opening_date = moment().format("YYYY-MM-DD");
          if (!this.account.branch_id) {
            let branch = storageService.getItem("user").branch || null;
            this.account.branch_id = branch.ID || "";
          }
        }
        return this.opened;
      },
      set: function (newValue) {
        this.$emit("update:opened", newValue);
      },
    },
  },

  watch: {
    accountProp: {
      handler: function () {
        this.accountProp.sub_accounts.forEach((el, index) => {
          this.accountDataSetForSubAccounts.push({
            account_definitions: [],
          });
          this.onSubAccountChanged(el.sub_account_type_id, index);
        });

        this.accountProp.account_holders.forEach((el) => {
          this.accountHolders.push(el);
        });

        this.account = _.cloneDeep(this.accountProp);
        this.account.branch_id = this.account.branch
          ? this.account.branch.ID
          : "";
        delete this.account["branch"];
      },
    },

    type: {
      handler: function (val) {
        this.selectedOption = this.type;
      },
    },

    "account.sub_accounts": {
      handler: function () {
        this.totalBalance = this.account.sub_accounts.reduce((acc, cur) => {
          return (acc += Number(cur.account_balance));
        }, 0);
      },

      deep: true,
    },
    "account.beneficiaries": {
      handler: function () {
        this.totalBeneficaryPercentage = this.account.beneficiaries.reduce(
          (acc, cur) => {
            return (acc += Number(cur.percentage_alloted));
          },
          0
        );
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

    showCustomerProfile(ev) {
      setTimeout(() => {
        this.customer = ev;
        this.customerDialog = true;
      }, 1000);
    },

    async initialize() {
      this.$validator.extend(
        validations.notNegative.name,
        validations.notNegative.validator
      );

      this.$validator.extend(
        validations.greaterThan.name,
        validations.greaterThan.validator
      );

      this.$validator.extend(
        validations.dateLessThan.name,
        validations.dateLessThan.validator
      );

      await this.fetchAccounts();
      await this.fetchAccountSettings();

      let response = await BranchService.findAll();
      this.branches = response.data;
      this.customerSearchFunc = _.debounce(this.findCustomer, 2000);
    },

    onBackBtnClicked() {
      this.$toast.question(
        "Returning to Options will cause you to lose recent information. Are you sure you want to perform this action.",
        "",
        {
          timeout: 20000,
          close: false,
          overlay: true,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              async (instance, toast) => {
                this.account = {
                  account_holders: [],
                  branch_id: "",
                  main_account_number: "",
                  external_account_number: "",
                  account_status: "",
                  account_opening_date: "",
                  account_last_transaction_date: "",
                  average_cash_deposit_amount: 0,
                  average_cheque_deposit_amount: 0,
                  average_internal_transfer_deposits: 0,
                  average_number_of_deposits: 0,
                  average_wire_transfers_amount: 0,
                  average_withdrawal_amount: 0,
                  sub_accounts: [],
                  authorisees: [],
                  beneficiaries: [],
                };
                this.tabModel = "tab-sub";
                this.accountHolders = [];
                this.selectedOption = "";
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
              true,
            ],
            [
              "<button>NO</button>",
              function (instance, toast) {
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
            ],
          ],
        }
      );
    },

    closeForm() {
      this.formOpened = false;
      this.selectedOption = "";
      this.accountHolders = [];
      this.tabModel = "tab-sub";
      this.$emit("closed", true);
    },

    chooseType(type) {
      this.selectedOption = type;
    },

    onSubAccountChanged(id, index) {
      let accountDefinitions = this.subAccountTypes.reduce((acc, val) => {
        if (val.ID === id) {
          acc = acc.concat(val.account_definitions);
        }
        return acc;
      }, []);
      this.accountDataSetForSubAccounts[index].account_definitions =
        accountDefinitions;
    },

    addSubAccount() {
      this.account.sub_accounts.push({
        ID: "",
        account_type_name: "",
        account_definition_name: "",
        sub_account_type_name: "",
        sub_account_type_id: "",
        account_status: "",
        account_balance: 0.0,
      });

      this.accountDataSetForSubAccounts.push({
        account_definitions: [],
      });
    },

    setSubAccountNumber(definition, index) {
      let count = 1;
      let code = "";
      let name = "";

      count += this.account.sub_accounts.reduce((acc, cur) => {
        if (cur.ID === definition) {
          acc += 1;
        }
        return acc;
      }, 0);

      this.accountDataSetForSubAccounts[index].account_definitions.forEach(
        (el) => {
          if (el.ID === definition) {
            code = el.account_code;
          }
        }
      );
      this.account.sub_accounts[index].sub_account;
      this.account.sub_accounts[index].sub_account_number = code + "" + count;
    },

    removeSubAccount(index) {
      this.account.sub_accounts.splice(index, 1);
    },

    removeAccountHolder(index) {
      this.account.account_holders.splice(index, 1);
      this.accountHolders.splice(index, 1);
      if (this.selectedOption === "business") {
        this.account.authorisees = [];
      }
    },

    removeAuthorisee(index) {
      this.account.authorisees.splice(index, 1);
    },

    removeBeneficiary(index) {
      this.account.beneficiaries.splice(index, 1);
    },

    addBeneficiary() {
      this.account.beneficiaries.push({
        name: "",
        relation: "",
        percentage_alloted: 0,
        date_of_birth: "",
      });
    },

    async fetchAccountSettings() {
      try {
        let result = await settingsService.findAccountSettings();

        if (result !== {}) {
          for (let key in result) {
            if (
              typeof result[key] === "string" &&
              result[key].indexOf("{'") > -1
            ) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }
          this.accountSettings = result;
        }
      } catch (e) {
        throw e;
      }
    },

    onSelectCreated(id) {
      let obj = this.customers.find((el) => el.ID === id);
      if (this.selectedOption !== "business") {
        if (obj) {
          if (this.selectedKey == "account_holders") {
            this.account.account_holders.push(obj.ID);
            this.accountHolders.push(obj);
          } else {
            this.account[this.selectedKey].push(obj);
          }
          this.customerSearchDialog = false;
          this.$nextTick(() => {
            this.searchCustomerModel = null;
          });
        }
      } else {
        if (obj) {
          this.account.account_holders.push(obj);
          this.accountHolders.push(obj);
          obj.owners = obj.owners.map((el) => {
            el.is_owner = true;
            return el;
          });
          this.account["authorisees"] = [];
          this.account["authorisees"] = this.account["authorisees"].concat(
            obj.owners
          );

          this.businessSearchDialog = false;
          this.$nextTick(() => {
            this.searchBusinessModel = null;
          });
        }
      }
    },

    onCustomerCreated(obj) {
      if (this.isYouth) {
        this.isYouth = false;
        this.youthSearchList = false;
        this.youthSearchDialog = false;
        this.youthSearchActivated = false;
        this.selectedOption = "youth";
        this.account.account_holders.push(obj);
        this.accountHolders.push(obj);
      } else if (this.selectedKey == "account_holders") {
        this.account.account_holders.push(obj);
        this.accountHolders.push(obj);
      } else {
        this.account[this.selectedKey].push(obj);
      }

      this.customerDialog = false;
    },

    onBusinessCreated(obj) {
      this.account.account_holders.push(obj);
      this.accountHolders.push(obj);

      this.account["authorisees"] = this.account["authorisees"].concat(
        obj.owners
      );
      this.businessDialog = false;
    },

    addCustomer(key) {
      this.selectedKey = key;
      this.customerDialog = true;
    },

    addBusiness() {
      this.businessDialog = true;
    },

    addExisting(key) {
      this.selectedKey = key;
      this.customerSearchDialog = true;
      this.customers = [];
    },

    addExistingBusiness() {
      this.businessSearchDialog = true;
      this.customers = [];
    },

    async submit() {
      let isValid = await this.validate();

      if (isValid) await this.save();
    },

    async validate() {
      let isValid = await this.$validator.validateAll("account");
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Please ensure the account details section is valid.",
        };
        return false;
      }

      isValid = this.account.sub_accounts.length == 0 ? false : true;
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Account must have at least one sub account.",
        };
        this.tabModel = "tab-sub";
        return false;
      }

      isValid = await this.$validator.validateAll("sub");
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Please ensure the sub account details are valid.",
        };
        this.tabModel = "tab-sub";
        return false;
      }

      isValid = this.account.account_holders.length == 0 ? false : true;
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Account must have at least one account holder.",
        };
        this.tabModel = "tab-details";
        return false;
      }

      isValid = await this.$validator.validateAll("ben");
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Please ensure the beneficiary details are valid.",
        };
        this.tabModel = "tab-beneficiary";
        return false;
      }

      if (this.totalBeneficaryPercentage > 100) {
        this.messageObj = {
          type: "error",
          message:
            "Total percentage alloted to beneficiaries cannot exceed 100.",
        };
        this.tabModel = "tab-beneficiary";
        return false;
      }

      isValid = await this.$validator.validateAll("trend");
      if (!isValid) {
        this.messageObj = {
          type: "error",
          message: "Please ensure the trend details are valid.",
        };
        this.tabModel = "tab-trend";
        return false;
      }

      return true;
    },

    async save() {
      try {
        this.isLoading = true;
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);

        let postData = _.cloneDeep(this.account);
        postData.authorisees = [];
        postData.account_holders = [];

        this.account.authorisees.forEach((el) =>
          postData.authorisees.push(el.ID)
        );

        for (const key in postData) {
          if (typeof postData[key] === "number") {
            postData[key] = postData[key].toFixed(2);
          }
        }

        postData.account_type =
          this.selectedOption.charAt(0).toUpperCase() +
          this.selectedOption.slice(1);

        this.accountHolders.forEach((el) =>
          postData.account_holders.push(el.ID)
        );

        if (!this.account.ID) {
          let createResult = await accountService.create(postData);
          let res = await accountService.findOne(createResult.data);
          this.$toast.success("Successfully created account record!", "OK", {
            position: "topRight",
          });
          this.account.ID = createResult.data;
          this.$emit("create", res.data);
        } else {
          await accountService.update(this.account.ID, postData);
          let res = await accountService.findOne(this.account.ID);
          this.$emit("update", res.data);
          this.$toast.success("Successfully updated account record!", "OK", {
            position: "topRight",
          });
        }
        this.closeForm();
      } catch (e) {
        if (e.response) {
          if (e.response.status === 409)
            this.$toast.error(e.response.data.error.message, "", {
              position: "center",
            });
          else this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
        }
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchAccounts() {
      try {
        this.isLoading = true;
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let response = await accountSettingsService.findAll();
        this.accountTypes = response.data;
        this.subAccountTypes = this.accountTypes.reduce((acc, cur) => {
          return acc.concat(cur.sub_account_types);
        }, []);
      } catch (e) {
        console.log("fetching: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    searchValueExist(searchVal) {
      let accountList = this.accountHolders.map((el) => ({
        ID: el.ID,
        trn: el.trn,
        full_name: el.first_name + " " + el.last_name,
      }));
      let authoriseesList = this.account.authorisees.map((el) => ({
        ID: el.ID,
        trn: el.trn,
        full_name: el.first_name + " " + el.last_name,
      }));
      let searchList = accountList.concat(authoriseesList);

      return searchList.filter((el) => el.trn === searchVal).length > 0;
    },

    onFindCustomer() {
      if (this.selectedOption === "business") return this.findBusiness();
      else return this.findCustomer();
    },

    async findCustomer() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      try {
        this.isLoading = true;
        let value =
          this.$refs.searchCustomerRef.$el.getElementsByTagName("input")[0]
            .value || "";
        if (this.searchValueExist(value)) {
          this.$toast.warning("Person already exist on account", "", {
            position: "center",
          });
          return;
        }

        let search = `trn=eq:${value}&`;
        let res = await customerService.findAll(search, "");
        this.customers = res.data;
      } catch (e) {
        console.log("fetching customer: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    async findBusiness() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      try {
        this.isLoading = true;
        let value =
          this.$refs.searchBusinessRef.$el.getElementsByTagName("input")[0]
            .value || "";

        let search = `business_name=eq:${value}&`;
        let res = await customerService.findBusinesses(search, "");
        this.customers = res.data;
      } catch (e) {
        console.log("fetching customer: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    /** youth account methods */
    async findYouth() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      let isValid = await this.$validator.validateAll("youth");
      if (!isValid) {
        return;
      }
      try {
        this.isLoading = true;
        let search = `first_name=eq:${this.youth.first_name}:and&last_name=eq:${this.youth.last_name}:and&date_of_birth=eq:${this.youth.date_of_birth}:and&guardian_last_name=eq:${this.youth.guardian_last_name}`;

        let res = await customerService.findAll(search, "");
        this.youthSearchList = res.data;
        this.youthSearchActivated = true;
      } catch (e) {
        console.log("youth search: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    openedYouthDIalog() {
      this.customer = { ...this.customer, ...this.youth };
      this.customerDialog = true;
      this.isYouth = true;
    },

    startYouthAccount(youth) {
      console.log(youth);
      this.youthSearchList = [];
      this.account.account_holders = [];
      this.account.account_holders.push(youth);
      this.accountHolders.push(youth);
      this.newYouthDialog = false;
      this.youthSearchActivated = false;
      this.selectedOption = "youth";
    },

    onYouthCreated(ev) {
      this.account.account_holders = [];
      this.account.account_holders.push(ev);
      this.accountHolders.push(ev);
      this.newYouthDialog = false;
      this.selectedOption = "youth";
      this.youth = {};
    },
  },
};
</script>

 
