<template>
  <div id="apps">
    <v-container fluid>
      <v-layout justify-start align-start>
        <h1 style="color: #172b4d">Apps</h1>
      </v-layout>
      <v-layout justify-end align-end class="mb-2">
        <!-- <input type="search" placeholder="Search"> -->
      </v-layout>
    </v-container>
    <v-container grid-list-lg class="mt-0 pt-0" fluid>
      <v-layout justify-center row wrap>
        <v-flex v-for="app in apps" :key="app.app_name" lg2 sm4 xs6 class="mt-1">
          <v-card color="#fff" href="#">
            <v-layout class="pl-1 pr-1">
              <!-- <v-btn small icon>
               <v-icon small>more_vert</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn v-if="app.app_settings" v-authorize="app.app_settings_privilege || ''" :to="app.app_settings" title="settings" style="right: 0px; position: absolute; " small icon>
               <v-icon color="#555" small>fa-cogs</v-icon>
              </v-btn>
            </v-layout>

            <v-layout justify-center align-center>
              <v-flex xs5>
                <v-img
                  :src="app.app_icon"
                  aspect-ratio="1"
                  class="grey lighten-2 img-circle"
                >
                  <v-layout slot="placeholder" ma-0>
                    <v-progress-circular indeterminate :size="20" color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                  <v-expand-transition>
                    
                  </v-expand-transition>
                </v-img>
              </v-flex>
            </v-layout>
            <v-card-text style="text-align:center; margin-top: -5px">
              <div style="color: #505a6b; font-size: 14px; word-wrap: wrap; font-weight: bold;">{{app.app_name}}</div>
              <v-btn small color="primary" @click="open(app)">OPEN</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style scoped>
.img-circle {
  border-radius: 50%;
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: #eee url(../../assets/img/outline-search.svg) no-repeat 9px center;
  border: solid 1px #ccc;
  padding: 9px 10px 9px 32px;
  width: 55px;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 200px;
  background-color: #fff;
  border-color: #ccc;
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}
</style>

<script>
import { mapMutations } from "vuex";
import companyService from "@/services/company-service";
import storageService from '@/services/storage-service'

export default {
  components: {},
  
  data() {
    return {
      user: null,
      apps: []
    };
  },

  created() {
    this.user = storageService.getItem('user')
    this.initialize();
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    this.SET_SIDEBAR_ROUTES([]);
  },

  methods: {
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SHOW_UNEXPECTED_ERROR_DIALOG",
      "SET_SIDEBAR_ROUTES"
    ]),

    async initialize() {
      try {
        this.apps = []
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let result = await companyService.fetchModules();
        for(let key in result) {
          let found = false
          this.user.app_access.forEach((app) => {
            if(app.app_name ===  result[key].app_name && result[key].is_active) {
              found = true;
            }
          })
          
          if(found || this.user.is_admin) {
            this.apps.push( result[key])
          }
        }
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    open(app) {
      if (app.app_type === "internal") {
        this.$router.replace(app.app_url);
      } else {
        window.location.assign(app.app_url);
      }
    }
  }
};
</script>
