<template>
  <div>

    <risk-factor-batch-load ref="batchLoad" @finished="fetchData"/>
    <!-- Dialogs -->
    <v-dialog v-model="riskfactorScoreDialog" persistent max-width="360">
      <v-card>
        <h2 class="pa-2">Modify {{selectedFactor.risk_factor_name}} Risk Factor Score</h2>  
        <v-divider></v-divider>
        <v-card-text>
       
          <va-input
            label="Score"
            v-validate="'required|max_value:1000|min_value:0|numeric'"
            data-vv-scope="riskfactor"
            data-vv-name="score"
            :error-messages="errors.collect('riskfactor.score')"
            placeholder="0"
            v-model="editedItem.risk_factor_score"
          ></va-input>
          <va-input
            label="Default Score (%)"
            :hint="'This is the score used as the score for a factor without a score'"
            persistent-hint
            v-validate="'required|max_value:100|min_value:-100|numeric'"
            data-vv-scope="riskfactor"
            data-vv-name="default_score"
            data-vv-as="default score"
            :error-messages="errors.collect('riskfactor.default_score')"
            placeholder="0"
            v-model="editedItem.default_risk_score"
          ></va-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn color flat @click="closeRiskFactorDialog">Close</v-btn>

          <v-btn color="primary" @click="onSaveRiskFactorScore">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="generateRangeDialog" persistent max-width="440">
      <v-card>
        <v-card-title class="headline">New {{selectedFactor.risk_factor_name}} Range Generation</v-card-title>

        <v-card-text>
          <v-layout row align-content-space-around="true">
            <va-input
              v-model="newRangefactor.min"
              v-validate="'required|min_value:0|max:50'"
              data-vv-scope="newRangefactor"
              :error-messages="errors.collect('newRangefactor.min')"
              placeholder=" "
              label="Starting value"
              data-vv-validate-on="blur"
              class="mr-2"
              data-vv-name="min"
              show-clean
            />
            <va-input
              v-model="newRangefactor.rows"
              v-validate="'required|decimal|min_value:1'"
              data-vv-scope="newRangefactor"
              :error-messages="errors.collect('newRangefactor.rows')"
              placeholder=" "
              label="Number of rows"
              data-vv-validate-on="blur"
              data-vv-name="rows"
              show-clean
            />
          </v-layout>
          <v-layout>
            <va-input
              v-model="newRangefactor.interval"
              v-validate="'required|numeric|min_value:1'"
              data-vv-scope="newRangefactor"
              :error-messages="errors.collect('newRangefactor.interval')"
              placeholder=" "
              class="mr-2"
              label="Intervals"
              data-vv-validate-on="blur"
              data-vv-name="interval"
              show-clean
            />

            <va-input
              v-model="newRangefactor.default_risk_score"
              v-validate="'required|decimal|min_value:-100|max_value:100'"
              data-vv-scope="newRangefactor"
              :error-messages="errors.collect('newRangefactor.score')"
              placeholder=" "
              label="Default Score"
              data-vv-validate-on="blur"
              data-vv-name="score"
              show-clean
            />
          </v-layout>
          <v-radio-group
            :disabled="selectedFactor.sub_risk_factors === undefined || selectedFactor.sub_risk_factors === false"
            :label="'Generate Option'"
            v-model="newRangefactor.option"
            style="margin-top: -10px"
          >
            <v-radio :label="`Merge score at index`" :value="'merge'"></v-radio>
            <v-radio :label="`Overwrite all scores`" :value="'overwrite'"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeGenerateRangeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="onGenerateRange">Generate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="subfactorDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">New Sub Risk Factor</v-card-title>

        <v-card-text>
          <va-input
            v-model="editedSubItem.sub_risk_factor_name"
            v-validate="'required|min:3|max:50'"
            data-vv-scope="newSubfactor"
            :error-messages="errors.collect('newSubfactor.name')"
            placeholder=" "
            label="Sub Factor Name"
            :max-width="'420px'"
            data-vv-validate-on="blur"
            data-vv-name="name"
            show-clean
          />

          <va-input
            v-model="editedSubItem.sub_risk_factor_score"
            v-validate="'required|decimal|min_value:-100|max_value:100'"
            data-vv-scope="newSubfactor"
            :error-messages="errors.collect('newSubfactor.score')"
            placeholder=" "
            label="Sub Factor Score"
            :max-width="'420px'"
            data-vv-validate-on="blur"
            data-vv-name="score"
            show-clean
          />
        </v-card-text>

        <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeNewSubFactorDialog">Cancel</v-btn>
          <v-btn color="primary" @click="onCreateSubFactor">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Right Navigation -->
    <v-navigation-drawer fixed v-model="riskFactorMenu" clipped app permanent right width="300">
      <v-text-field
        append-icon="search"
        v-model="riskFactorSearch"
        label="Search for a Risk Factor"
        class="mx-3"
      ></v-text-field>
      <v-list class="pt-0 mt-0">
        <v-list-tile
          v-for="riskfactor in filterFactors(riskfactors)"
          :key="riskfactor.ID"
          active-class="active"
          v-bind:class="{ active: selectedFactor.risk_factor_name ===riskfactor.risk_factor_name }"
        >
          <v-list-tile-action>
            <v-checkbox
              v-model="riskfactor.risk_factor_is_active"
              @change="onRiskFactorActiveToggle(riskfactor)"
            ></v-checkbox>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{riskfactor.risk_factor_name}}</v-list-tile-title>
            <v-list-tile-sub-title>{{riskfactor.risk_factor_type +' - '+ riskfactor.risk_factor_score }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-avatar>
            <v-btn flat icon small @click="onViewRiskFactorClicked(riskfactor)">
              <v-icon color="#444" small>fa-eye</v-icon>
            </v-btn>
            <v-btn flat icon small @click="onEditRiskFactorClicked(riskfactor)">
              <v-icon color="#444" small>edit</v-icon>
            </v-btn>
          </v-list-tile-avatar>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <!-- Sub Risk factor table -->
    <v-card class="pa-2 pl-4 mb-3 elevation-0">
      <h2>
       Sub Risk Factor Management
      <span style="float:right; margin-top: -5px">

        <v-btn dark @click="$refs.batchLoad.open()">Batch Load</v-btn>
        <v-chip label>{{selectedFactor.risk_factor_name || 'None Selected'}}</v-chip>
      </span>
    </h2>
    </v-card>
    <div v-if="selectedFactor">
      <v-layout>
        <va-input
          v-model="search"
          :width="'300px'"
          class="va-form-control"
          append-icon="search"
          label
          placeholder="Search"
        ></va-input>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          v-if="selectedFactor.risk_factor_type === 'Name'"
          dark
          class="mb-2"
          @click="resetNewSubFactorDialog(); subfactorDialog = true"
        >New</v-btn>
        <v-btn
          color="primary"
          v-if="selectedFactor.risk_factor_type === 'Range'"
          dark
          class="mb-2"
          @click="generateRangeDialog = true;"
        >Generate Range</v-btn>
      </v-layout>

      <v-data-table
        must-sort
        :loading="riskFactorState.isLoading"
        class="elevation-1"
        :search="search"
        :headers="headers"
        :items="selectedFactor.sub_risk_factors"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-switch
              class="mt-3"
              v-model="props.item.sub_risk_factor_is_active"
              label
              @change="toggleSubRiskFactorActive(props.item)"
            ></v-switch>
          </td>

          <td>
            <v-edit-dialog
              :persistent="selectedFactor.risk_factor_type != 'Range'"
              :large="selectedFactor.risk_factor_type != 'Range'"
              :style="disableEventStyle"
              lazy
              @save="onEditDialogSaved"
              @cancel="onEditDialogClosed"
              @open="onEditDialogOpened(props.item)"
            >
              <span v-if="selectedFactor.risk_factor_type == 'Range'">
                {{props.item.sub_risk_factor_range}}
              </span>
              <span v-else>
                {{ props.item.sub_risk_factor_name }}
              </span>
              <v-text-field
                slot="input"
                v-if="selectedFactor.risk_factor_type !== 'Range'"
                v-validate="'required|min:0|max:50'"
                data-vv-scope="subFactor"
                data-vv-name="name"
                :error-messages="errors.collect('subFactor.name')"
                v-model="selectSubRiskFactor.sub_risk_factor_name"
                label="Edit"
                single-line
                counter
              ></v-text-field>
            </v-edit-dialog>
          </td>
          <td>
            <v-edit-dialog
              large
              lazy
              @save="onEditDialogSaved"
              @cancel="onEditDialogClosed"
              :style="disableEventStyle"
              persistent
              @open="onEditDialogOpened(props.item)"
            >
              {{ props.item.sub_risk_factor_score + ' %' || '0 %' }}
              <v-text-field
                slot="input"
                v-validate="'required|max_value:100|decimal:2'"
                data-vv-scope="subFactor"
                data-vv-name="score"
                :error-messages="errors.collect('subFactor.score')"
                v-model="selectSubRiskFactor.sub_risk_factor_score"
                label="Edit"
                single-line
              ></v-text-field>
            </v-edit-dialog>
          </td>

          <td>{{props.item.date_created}}</td>

          <td>
            <v-icon small @click="onDeleteSubFactor(props.item)">delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-container grid-list-lg class="mt-0 pt-0" fluid>
        <v-layout justify-center row wrap>
          <v-flex sm4 class="mt-5">
            <v-card flat width="200px" class="pa-3" style="text-align: center">
              <v-icon large>fa-eye</v-icon>
              <p>
                Select this icon to view
              </p>
            </v-card>
          </v-flex>
          <v-flex sm4 class="mt-5">
            <v-card flat width="200px" class="pa-3" style="text-align: center">
              <v-icon large>edit</v-icon>
              <p>
                Select this icon to edit
              </p>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<style type="css" scoped>
h1,
h2,
h3 {
  color: #505a6b;
}

.active {
  background: #eee;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import VaInput from "@/components/form-components/VaInput.vue";
import RiskFactorBatchLoad from '@/components/batch-insert/RiskFactorBatchLoad.vue';

export default {
  components: {
    RiskFactorBatchLoad,
    VaInput
  },

  created() {
    this.initialize();
  },

  beforeDestroy() {
    this.$toast.destroy();
  },

  data() {
    return {
      search: "",
      disableEventStyle: "",
      riskFactorSearch: "",
      riskFactorMenu: false,
      riskfactorScoreDialog: false,
      subfactorDialog: false,
      generateRangeDialog: false,
      dialog: false,
      headers: [
        { text: "Active", align: "left", sortable: false },
        {
          text: "Min Value",
          align: "left",
          sortable: true,
          value: "sub_risk_factor_name"
        },
        {
          text: "Score",
          align: "left",
          sortable: true,
          value: "sub_risk_factor_score"
        },
        { text: "Date Created", sortable: false, value: "" },
        { text: "Action", sortable: false, value: "" }
      ],
      riskfactors: [],
      previousValue: 0,
      selectedFactor: false,
      editedSubItem: {},
      editedIndex: -1,
      editedItem: {},
      selectSubRiskFactor: {},
      subFactorIndex: -1,
      previousSubFactor: {},
      previousDefaultScore:0,
      newRangefactor: {}
    };
  },

  computed: {
    ...mapState(["appState", "riskFactorState"]),

    formTitle() {
      return this.editedIndex === -1
        ? "New Sub Risk Factor"
        : "Edit Sub Risk Factor";
    }
  },

  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG", "UPDATE_SUB_RISK_FACTOR"]),
    ...mapActions([
      "getRiskFactors",
      "updateRiskFactor",
      "updateSubRiskFactor",
      "createSubFactor",
      "deleteSubFactor"
    ]),

    async initialize() {
      try {
        this.resetState();
        this.editedSubItem = Object.assign(
          {},
          this.riskFactorState.defaultSubRiskFactor
        );
        this.newRangefactor = Object.assign(
          {},
          this.riskFactorState.defaultRangeOptions
        );
        await this.fetchData();
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    closeRiskFactorDialog() {
      this.riskfactorScoreDialog = false;
      this.editedItem.risk_factor_score = this.previousValue;
      this.editedItem.default_risk_score = this.previousDefaultScore;
      this.editedItem = Object.assign({}, {});
      this.$validator.reset({ scope: "riskfactor" });
    },

    async fetchData() {
      try {
        this.resetState();

        await this.getRiskFactors();
        this.riskfactors = this.riskFactorState.riskfactors.filter( el => !el.is_only_adjustment );
        this.totalCount = this.riskfactors.length;
      } catch (e) {
        console.log("fetching data: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    onEditRiskFactorClicked(factor) {
      this.editedIndex = this.riskfactors.findIndex((el) => factor.ID === el.ID );
      this.editedItem = factor;
      this.previousValue = factor.risk_factor_score;
      this.previousDefaultScore = factor.default_risk_score;
      this.riskfactorScoreDialog = true;
    },

    onViewRiskFactorClicked(factor) {
      this.selectedFactor = factor;
      this.editedIndex = this.riskfactors.findIndex((el) => factor.ID === el.ID )
      if (this.selectedFactor.risk_factor_type === "Name") {
        this.headers[1].text = "Name";
      } else {
        let len = this.selectedFactor.sub_risk_factors.length - 1
        this.selectedFactor.sub_risk_factors.forEach((el, i)=>{
          if(i == 0){
            const next = Number(this.selectedFactor.sub_risk_factors[i+1].sub_risk_factor_name) - 1
            el.sub_risk_factor_range = len == i ? `0 - ${el.sub_risk_factor_name}` : `0  ${Number(el.sub_risk_factor_name) != next ? " - "+next: ""} or less`
          } else if(i == len) {
            el.sub_risk_factor_range = `${el.sub_risk_factor_name} and up` 
          } else{
            const next = Number(this.selectedFactor.sub_risk_factors[i+1].sub_risk_factor_name) - 1
            el.sub_risk_factor_range = `${el.sub_risk_factor_name} - ${next}`
          }

        })
        
        this.headers[1].text = "Range";
      }
    },

    async onRiskFactorActiveToggle(factor) {
      this.$toast.destroy();
      this.editedIndex = this.riskfactors.findIndex((el) => factor.ID === el.ID );
      this.editedItem = factor;
      try {
        await this.updateRiskFactorScore();
      } catch (e) {
        factor.risk_factor_is_active = !factor.risk_factor_is_active;
      }
    },

    async onSaveRiskFactorScore() {
      try {
        this.resetState();

        let isValid = await this.$validator.validateAll("riskfactor");
        if (isValid) {
          await this.updateRiskFactorScore();
        } else {
          this.$toast.error(
            "Please check to ensure that the value entered is valid.",
            "",
            { position: "topCenter" }
          );
        }
      } catch (e) {
        console.log("updating risk factor: ", e);
      }
    },

    async updateRiskFactorScore() {
      this.$toast.destroy();
      try {
        await this.updateRiskFactor({
          selectedFactor: this.selectedFactor,
          index: this.editedIndex,
          obj: this.editedItem
        });
        this.$toast.success("Successfully updated record.", "", {
          position: "topCenter"
        });
        this.previousValue = this.editedItem.risk_factor_score;
        this.previousDefaultScore = this.editedItem.default_risk_score;
        this.closeRiskFactorDialog();
      } catch (e) {
        console.log("updating risk factor: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    filterFactors(factors) {
      var app = this;
      return factors.filter(function(factor) {
        return (
          factor.risk_factor_name
            .toLowerCase()
            .indexOf(app.riskFactorSearch.toLowerCase()) != -1
        );
      });
    },

    toggleActive(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.save();
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },
    /** new Sub factor dialog methods */
    resetNewSubFactorDialog() {
      this.$validator.reset({ scope: "newSubfactor" });
      Object.assign(
        this.editedSubItem,
        this.riskFactorState.defaultSubRiskFactor
      );
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },

    closeNewSubFactorDialog() {
      this.editedSubItem = { ...{} };
      this.subFactorIndex = -1;
      this.subfactorDialog = false;
    },

    async onCreateSubFactor() {
      this.resetState();
      let exist = this.doesRecordExist();
      if (exist) return;

      let isValid = await this.$validator.validateAll("newSubfactor");

      try {
        if (isValid) {
          let objs = [];
          this.selectedFactor.sub_risk_factors.forEach(el => {
            objs.push(el);
          });
          objs.push({ ...this.editedSubItem });
          await this.createSubFactor({
            selectedFactor: this.selectedFactor,
            factorIndex: this.editedIndex,
            objs: objs
          });
          this.$toast.success("Successfully created sub-risk factors.", "", {
            position: "topCenter"
          });
          this.closeNewSubFactorDialog();
        } else {
          this.$toast.error(
            "Please check to ensure that the value entered is valid.",
            "",
            { position: "topCenter" }
          );
        }
      } catch (e) {
        console.log("updating sub risk factor: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    /** Range generation methods */
    async onGenerateRange() {
      let isValid = await this.$validator.validateAll("newRangefactor");
      let start = Number(this.newRangefactor.min);
      let rows = Number(this.newRangefactor.rows);
      let interval = Number(this.newRangefactor.interval);
      try {
        this.resetState();
        if (isValid) {
          //Generate Ranges with the defined interval
          let objs = [];
          let ranges = [];
          let i = start;
          while (ranges.length < rows) {
            if (i === 0 || i % interval == 0) ranges.push(i);

            i++;
          }

          ranges.forEach(i => {
            let obj = Object.assign(
              {},
              this.riskFactorState.defaultSubRiskFactor
            );
            obj.sub_risk_factor_name = i.toString();

            obj.sub_risk_factor_score = Number(
              this.newRangefactor.default_risk_score
            );
            objs.push(obj);
          });

          //Merge generated range values with previously stored values if exist
          if (
            this.newRangefactor.option == "merge" &&
            this.selectedFactor.sub_risk_factors.length > 0
          ) {
            this.selectedFactor.sub_risk_factors.forEach((el, i) => {
              if (i < objs.length) {
                objs[i].sub_risk_factor_score = el.sub_risk_factor_score;
              }
            });
          }
          await this.createSubFactor({
            selectedFactor: this.selectedFactor,
            factorIndex: this.editedIndex,
            objs: objs
          });
          this.$toast.success("Successfully generated sub-risk factors.", "", {
            position: "topCenter"
          });
          this.closeGenerateRangeDialog();
        } else {
          this.$toast.error(
            "Please check to ensure that the value entered is valid.",
            "",
            { position: "topCenter" }
          );
        }
      } catch (e) {
        console.log("updating range sub risk factor: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    closeGenerateRangeDialog() {
      this.generateRangeDialog = false;
      this.$validator.reset({ scope: "newRangefactor" });
      this.newRangefactor = Object.assign(
        {},
        this.riskFactorState.defaultRangeOptions
      );
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },

    /** Inline Edit dialog methods */
    onEditDialogOpened(item) {
      this.disableEventStyle = "pointer-events: none";
      this.search = "";
      this.$validator.reset({ scope: "subFactor" });
      this.subFactorIndex = this.selectedFactor.sub_risk_factors.indexOf(item);
      this.selectSubRiskFactor = item;
      this.previousSubFactor = Object.assign({}, item);
    },

    onEditDialogClosed() {
      this.disableEventStyle = "";
      this.resetEditDialog();
    },

    async onEditDialogSaved() {
      this.resetState();
      let exist = this.doesRecordExist();
      if (exist) {
        this.resetEditDialog();
        return;
      }

      let isValid = await this.$validator.validateAll("subFactor");
      if (!isValid) {
        this.$toast.error(
          "Please check to ensure that the value entered is valid.",
          "",
          { position: "topCenter" }
        );
      } else {
        await this.updateSubFactor();
      }
      this.disableEventStyle = "";
      this.resetEditDialog();
    },

    async toggleSubRiskFactorActive(item) {
      try {
        this.resetState();
        this.subFactorIndex = this.selectedFactor.sub_risk_factors.findIndex((el) => el.ID === item.ID)
        this.selectSubRiskFactor = this.selectedFactor.sub_risk_factors.find((el) => el.ID === item.ID)
        console.log(item, this.subFactorIndex)
        await this.updateSubFactor();
      } catch (e) {
        console.log(e)
        this.selectedFactor.sub_risk_factors[
          this.subFactorIndex
        ].sub_risk_factor_is_active = !this.selectedFactor.sub_risk_factors[
          this.subFactorIndex
        ].sub_risk_factor_is_active;
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async updateSubFactor() {
      try {
        this.$toast.destroy();
        this.resetState();
        await this.updateSubRiskFactor({
          selectedFactor: this.selectedFactor,
          subIndex: this.subFactorIndex,
          obj: this.selectSubRiskFactor
        });
        console.log('selectSubRiskFactor', this.selectSubRiskFactor)
        this.previousSubFactor = this.selectedFactor.sub_risk_factors[
          this.subFactorIndex
        ];
        this.$toast.success("Successfully updated record.", "", {
          position: "topCenter"
        });
      } catch (e) {
        console.log("updating sub factor: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
        throw e;
      }
    },

    doesRecordExist() {
      let items = [];
      let existProps = [];
      let msg = "A record already exist with the following values: ";
      if (this.subFactorIndex > -1) {
        items = this.selectedFactor.sub_risk_factors.filter(el => {
          return (
            el.ID !==
            this.selectedFactor.sub_risk_factors[this.subFactorIndex].ID
          );
        });
        items.forEach(el => {
          if (
            this.selectSubRiskFactor.sub_risk_factor_name.toLowerCase() ===
            el.sub_risk_factor_name.toLowerCase()
          ) {
            existProps.push("Sub Factor Name: " + el.sub_risk_factor_name);
          }
        });
      } else {
        items = this.selectedFactor.sub_risk_factors;
        items.forEach(el => {
          if (
            this.editedSubItem.sub_risk_factor_name.toLowerCase() ===
            el.sub_risk_factor_name.toLowerCase()
          ) {
            existProps.push("Sub Factor Name: " + el.sub_risk_factor_name);
          }
        });
      }

      if (existProps.length > 0) {
        this.$$toast.warning(msg + existProps.join(", "), "", {
          position: "topCenter",
          timeout: 7000
        });
        return true;
      }

      return false;
    },

    resetEditDialog() {
      Object.keys(
        this.selectedFactor.sub_risk_factors[this.subFactorIndex]
      ).forEach(key => {
        this.selectedFactor.sub_risk_factors[this.subFactorIndex][
          key
        ] = this.previousSubFactor[key];
      });
      this.subFactorIndex = -1;
      this.previousSubFactor = {};
      this.$validator.reset({ scope: "subFactor" });
    },

    async onDeleteSubFactor(item) {
      this.resetState();
      this.$toast.question(
        "Are you sure you want to delete this sub risk factor?",
        "",
        {
          timeout: 20000,
          close: false,
          overlay: true,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              async (instance, $toast) => {
                try {
                  let index = this.selectedFactor.sub_risk_factors.findIndex((el) => el.ID === item.ID)
                  await this.deleteSubFactor({
                    selectedFactor: this.selectedFactor,
                    index: index
                  });
                  this.$toast.warning("Risk factor has been removed.", "", {
                    position: "topCenter"
                  });
                } catch (e) {
                  console.log("updating sub factor: ", e);
                  this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
                  throw e;
                }
                instance.hide({ transitionOut: "fadeOut" }, $toast, "button");
              },
              true
            ],
            [
              "<button>NO</button>",
              function(instance, $toast) {
                instance.hide({ transitionOut: "fadeOut" }, $toast, "button");
              }
            ]
          ]
        }
      );
    }
  }
};
</script>

 
