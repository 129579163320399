<template>
 <div>
  <v-dialog v-model="modal" persistent max-width="520">
    <v-card>
      <v-card-title ><h2>{{formTitle}}</h2></v-card-title>
      <v-card-text class="mb-4">

        <va-input
          v-model="item.account_code"
          v-validate="'required|min:2|max:10'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.account_code')"
          placeholder=" "
          label="Account Code"
          data-vv-validate-on="blur"         
          data-vv-name="account_code"
          data-vv-as="account code"
          show-clean />

        <va-input
          v-model="item.account_name"
          v-validate="'required|min:3|max:45'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.account_name')"
          placeholder=" "
          label="Account Name"
          data-vv-validate-on="blur"         
          data-vv-name="account_name"
          data-vv-as="account name"
          show-clean />

        <va-input
          v-model="item.minimum_deposit_amount"
          v-validate="'required|not_negative'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.minimum_deposit_amount')"
          placeholder=" "
          v-money="money"
          ref="min"
          label="Minimum Deposit Amount"
          data-vv-validate-on="blur"         
          data-vv-name="minimum_deposit_amount"
          data-vv-as="minimum deposit amount"
          show-clean />

        <va-select 
          v-model="item.sub_type_id"
          v-validate="'required'"
          placeholder=" "
          label="Sub Account Type"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.sub_type_id')"
          :items="subTypes"
          :item-value="'ID'"
          :item-text="'sub_account_type_name'"
          data-vv-as="sub account"
          data-vv-name="sub_type_id"/>

        <label for="" class="grey--text lighten-1 mb-3">Description</label>
        <wysiwyg 
          disabled 
          data-vv-scope="loan" 
          v-validate="'min:0|max:2000'"
          data-vv-name="description" 
          v-model="item.description" />
        <small v-if="errors.first('loan.description')" class="red--text">
          {{errors.first('loan.description')}}
        </small>
        

      </v-card-text>

      <v-card-actions style="margin-top:-30px" class="ml-4 mr-2">
        <v-spacer></v-spacer>
        <v-btn flat @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submit">{{item.ID ? 'Update' : 'Save'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-toolbar dense color="white" class="pa-0">
    <v-text-field
      v-model="search"
      solo
      label="Search"
      prepend-inner-icon="search"
    ></v-text-field>

    <v-divider class="mr-2" vertical></v-divider>

    <v-btn  color="primary" @click="openModal">
      ADD Account Definition
    </v-btn>
  </v-toolbar>
  <v-data-table 
    :loading="isLoading"
    :search="search"
    :headers="tblHeaders" 
    :items="items" 
    :rows-per-page-items='[10,20, 30,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    class="elevation-1">
    <template slot="items" slot-scope="props">
      <td><v-checkbox  @change="toggleSwitch(props.item)"  class="mt-3" v-model="props.item.is_active"></v-checkbox></td>
      <td class="text-xs-left"><v-checkbox  @change="toggleSwitch(props.item)"  class="mt-3" v-model="props.item.available_on_e_service"/></td>
      <td class="text-xs-left">{{ props.item.account_code }}</td>
      <td class="text-xs-left">{{ props.item.account_name | capitalize }}</td>
      <td class="text-xs-left">{{ props.item.account_type_name | capitalize }}</td>
      <td class="text-xs-left">{{ props.item.sub_type_name | capitalize }}</td>
      <td class="text-xs-left">{{ props.item.minimum_deposit_amount | currency  }}</td>
      <td >
        <v-icon title="edit" small class="mr-2" @click="edit(props.item)">
          edit
        </v-icon>
        <v-icon small title="delete" class="mr-2" @click="onDeleteItemClicked(props.item)">
          delete
        </v-icon>
      </td>
    </template>
  </v-data-table>
  <v-dialog v-model="deleteDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <h3 class="text-xs-center pb-3">In order to delete this account definition you must select a replacement.</h3>  
        
        <va-select 
          v-model="replacementID"
          v-validate="'required'"
          placeholder=" "
          label="Select a replacement account definition"
          data-vv-scope="delete"
          data-vv-as="replacement account definition"
          :error-messages="errors.collect('delete.replacement')"
          :items="items.filter( (el) => el.ID !== item.ID )"
          :item-text="'account_name'"
          :item-value="'ID'"
          data-vv-name="replacement"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="red darken-3" flat @click="deleteItem">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapMutations } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import accountSettingsService from '@/services/account-settings-service'
import validations from '@/common/validator'

export default {
  components: {
    VaInput,
    VaSelect
  },

  created () {
    this.initialize()
  },
  
  data() {
    return {
      modal: false,
      isLoading: false,
      deleteDialog: false,
      replacementID: '',
      search: '',
      tblHeaders: [
        { text: 'Active', align: 'left', sortable: false },
        { text: 'Active on E-Service', align: 'left', sortable: false, value: 'available_on_e_service'},
        { text: 'Code', align: 'left', sortable: false, value: 'account_code'},
        { text: 'Name', align: 'left', sortable: false, value: 'account_name'},
        { text: 'Account Type', align: 'left', sortable: false, value: 'account_type_name'},
        { text: 'Sub Type', align: 'left', sortable: false, value: 'sub_type_name'},
        { text: 'Minimum Deposit', align: 'left', sortable: false, value: 'minimum_deposit_amount'},
        { text: 'Action', align: 'left', sortable: false },
      ],
      defaultItem: {
        account_name: "",
        account_code: "",
        sub_type_id:"",
        minimum_deposit_amount: 0,
        is_active: true
      },
      money: {
          decimal: '.',
          thousands: ',',
          prefix: '',
          precision: 2,
          masked: true
      },
      item: {},
      subTypes: [],
      items: [],
    }
  },

  computed: {
    ...mapState([
      'appState',
      'loanItemState',
      'deleteLoanItem'
    ]), 
    
    formTitle () {
      return this.item.ID ? 'Edit Account Definition' : 'New Account Definition'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    openModal() {
      this.$toast.destroy()
      this.item = {...this.defaultItem}
      this.modal = true
    },

    closeModal() {
      this.$validator.reset()
      this.item = {...this.defaultItem}
      this.modal = false
      this.$refs.min.$el.getElementsByTagName('input')[0].value = 0
    },

    edit(item) {
      this.item = {...item}   
      this.$refs.min.$el.getElementsByTagName('input')[0].value = this.item.minimum_deposit_amount  
      this.modal = true
    },

    async initialize() {
      this.$validator.extend(validations.notNegative.name, validations.notNegative.validator)
      await this.fetchData()
    },

    onDeleteItemClicked(item) {
      this.item = {...item}
      this.deleteDialog = true
    },

    async deleteItem() {
      let isValid = await this.$validator.validateAll("delete")
      if(!isValid) return
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      this.$toast.question('Are you sure you want to delete this account type?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              await accountSettingsService.deleteAccountDefinition(this.item.ID, this.replacementID)

              let len = this.items.length
              for(let i = 0; i < len; i++) {
                if(this.item.ID == this.items[i].ID) {
                  this.items.splice(i,1)
                  break
                }
              }
              this.deleteDialog = false

              this.$toast.warning('Account has been deleted!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('deleting: ',e)
              if(e.response) {
                if(e.response.status === 404)
                  this.$toast.error("Account has been already deleted", '',{position: 'center'})
                else
                  this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              }
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    async submit() {
      if(this.item.required_at_pre_approval == false &&
         this.item.required_at_reviewing == false &&
         this.item.required_at_approval == false &&
         this.item.required_at_pre_disbursement == false) {
           this.$toast.error('Please select at least one of the stages this item is required at.', '', {position: 'topCenter'})
           return
      }

      let isValid = await this.$validator.validateAll("loan")
      if(isValid) {
        await this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },

    toggleSwitch(item) {
      this.item = {...item}
      this.save()
    },

    async save () {
      try {
        this.$toast.destroy();
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true
        let data = {...this.item}

        delete data['date_created']
        delete data['date_updated']
        delete data['sub_type_id']
        delete data['sub_type_name']
        delete data['account_type_name']

        this.subTypes.forEach((el)=>{
          if(el.ID === this.item.sub_type_id) {
            this.item.sub_type_name = el.sub_account_type_name
            this.item.account_type_name = el.account_type_name
          }
        })

        data.minimum_deposit_amount = !data.minimum_deposit_amount ? 0 : Number(data.minimum_deposit_amount.toString().replace(/,/g, ""))
        if (this.item.ID) {
          await accountSettingsService.updateDefinitionType(this.item.sub_type_id, this.item.ID, data)
          let len = this.items.length
          for(let i = 0; i < len; i++) {
            if(this.item.ID == this.items[i].ID) 
              this.items.splice(i, 1, {...this.item})
          }
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          
          let res = await accountSettingsService.createDefinitionType(this.item.sub_type_id, data)
          this.item.ID = res.data
          this.items.push(this.item)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.closeModal()
       
      } catch(e) {
        console.log('saving/updating: ',e)
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      } finally {
        this.isLoading = false
      }
    },

    async fetchData() {
      try {
        this.isLoading = true
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let response = await accountSettingsService.findAll() 
        this.subTypes =response.data
          .filter((el) => el.sub_account_types.length > 0)
          .reduce((acc, cur) => {
            let subs = cur.sub_account_types.map((el)=>{
              el.account_type_id = cur.ID
              el.account_type_name = cur.account_type_name
              return el
            })
            return acc.concat(subs)
          }, [])

        this.items = response.data
          .filter((el) => el.sub_account_types.length > 0)
          .reduce((acc, cur) => {
            let subs = cur.sub_account_types
              .filter((el) => el.account_definitions.length > 0)
              .map((el) => {
                el.account_type_id = cur.ID
                el.account_type_name = cur.account_type_name
                return el
              })
              
            return acc.concat(subs)
          }, []).reduce((acc, val) => {
            let defs = val.account_definitions
              .map((el) => {
                el.account_type_name = val.account_type_name
                el.sub_type_id = val.ID
                el.sub_type_name = val.sub_account_type_name
                return el
              })
            return acc.concat(defs)
          }, [])
         
        console.log(this.items)
      } catch(e) {
        console.log('fetching: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },
  },

}
</script>

 
