<template>
    <div>

        <!-- Instructions Dialog -->
        <template>
            <div class="text-xs-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-title class="title" primary-title>
                            <v-icon class="mr-2">list</v-icon> {{ selectedItem.title }}: Instructions
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-html="selectedItem.instructions">
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" flat @click="dialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>

        <!-- Exceptions Dialog -->
        <template>
            <div class="text-xs-center">
                <v-dialog v-model="exceptions_dialog" width="800">
                    <v-card>
                        <v-card-title class="title" primary-title>
                            <v-icon class="mr-2">list</v-icon> The following accounts where not updated :
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-data-table :headers="headers" :items="this.ux.exceptions" class="elevation-1">
                                <template slot="items" slot-scope="props">
                                    <td>{{ props.item.account }}</td>
                                    <td>{{ props.item.exception }}</td>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" flat @click="exceptions_dialog = false">
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>

        <!-- Upload Options -->
        <v-card>
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Mass Upload</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="ux.processing">
                <div class="mx-3">
                    <p class="title blinking mt-3 text-md-center"><span class="text-capitalize">{{ ux.option }}</span>:
                        {{ ux.progress }} </p>
                    <v-progress-linear v-if="uploadProgress < 100" v-model="uploadProgress"></v-progress-linear>
                    <v-progress-linear :indeterminate="true" v-else></v-progress-linear>
                </div>
            </v-card-text>

            <v-card-text v-if="ux.success" class="text-md-center mt-4">
                <div class="mx-3 d-inline-flex">
                    <v-icon x-large color="green">fas fa-check-circle</v-icon>
                    <p class="display-1 ml-2">Successfully Uploaded</p>
                </div>
                <p class="title">Mass Upload successful. Processing results below:</p>
                <div class="text-xs-center mx-auto">
                    <span v-if="ux.exceptions">
                        <v-chip class="ma-2" color="warning" @click="exceptions_dialog = true" outlined>
                            Exceptions
                        </v-chip>
                        <br>
                    </span>
                    <span v-if="ux.totalPersons"> Persons: <b>{{ ux.totalPersons }}</b> <br></span>
                    <span v-if="ux.totalLoans"> Total Loan Amount: <b>{{ asMoney(ux.totalLoans) }}</b><br></span>
                    <span v-if="ux.totalCC">Total Cash Collateral Amount: <b>{{ asMoney(ux.totalCC) }}</b><br></span>
                    <span v-if="ux.totalMVC">Total Motor Vehicle Collateral Amount:
                        <b>{{ asMoney(ux.totalMVC) }}</b><br></span>
                    <span v-if="ux.totalREC">Total Real Estate Collateral Amount:
                        <b>{{ asMoney(ux.totalREC) }}</b><br></span>
                    <span v-if="ux.countTransactions">Count Transactions: <b>{{ ux.countTransactions }}</b><br></span>
                    <span v-if="ux.sumTransactions">Sum Transactions: <b>{{ asMoney(ux.sumTransactions) }}</b>
                        <br></span>
                </div>
            </v-card-text>

            <v-card-text v-if="ux.error" class="text-md-center mt-4">
                <div class="mx-3 d-inline-flex">
                    <v-icon x-large color="red">fas fa-exclamation-circle</v-icon>
                    <p class="display-1 ml-2">Server Error</p>
                </div>
                <p class="title">Failed to <b>process file</b> on server. Try checking the file requirements and try
                    again.</p>
                <div class="text-md-center">
                    <li>Ensure the file provided for upload is of CSV file format.</li>
                    <li>Column headers should be placed at ROW 1 - the top of the file.</li>
                    <li>All dates in file should follow the YYYY-MM-DD date format for processing.</li>
                </div>
            </v-card-text>
            <!-- 
            <v-card-text v-if="ux.valid" class="text-md-center mt-4">
                <div class="mx-3 d-inline-flex">
                    <v-icon x-large color="yellow">fas fa-exclamation-circle</v-icon>
                    <p class="display-1 ml-2">Server Error</p>
                </div>
                <p class="title">{{ux.message}}</p>
            </v-card-text> -->
            <!-- <hr class="mt-4"> -->
            <v-card-text>
                <v-list two-line subheader>
                    <template v-for="(item, index) in uploadMethods">
                        <v-list-tile :key="item.title" avatar>

                            <v-list-tile-action>
                                <v-list-tile-action-text>
                                    <input class="inputfile" type="file" id="file" ref="file"
                                        v-on:change="handleFileUpload(index)" />
                                </v-list-tile-action-text>
                            </v-list-tile-action>

                            <v-list-tile-content>
                                <v-list-tile-title>{{item.title}}</v-list-tile-title>
                                <v-list-tile-sub-title>{{item.subtitle}}<v-icon @click="showInstructions(item)"
                                        class="ml-3">info</v-icon>
                                </v-list-tile-sub-title>
                            </v-list-tile-content>

                            <v-btn v-show="item.show" color="green" class="white--text" v-on:click="validateFile(item)"
                                depressed>
                                <v-icon left>cloud_upload</v-icon>
                                Start Upload
                            </v-btn>

                        </v-list-tile>
                        <v-divider v-if="index + 1 < uploadMethods.length" :key="index"></v-divider>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>

    </div>
</template>

<style scoped>
    .inputfile {
        font-size: 16px;
    }
</style>

<script scoped>
    import MassUploadService from "@/services/mass-upload-service";
    import {
        mapMutations
    } from "vuex";

    export default {
        data() {
            return {
                uploadProgress: 0,
                ux: {

                    processing: false,
                    success: false,
                    error: false,
                    option: '',
                    progress: '',

                    totalPersons: 0,
                    totalLoans: 0,
                    totalCC: 0,
                    totalMVC: 0,
                    totalREC: 0
                },

                file: '',
                totalPersons: 0,
                totalLoans: 0,
                invalidRecords: [],
                LoaderDialog: false,
                showButton: false,
                dialogInfo: {
                    title: '',
                    message: ''
                },
                loader: false,
                error: false,
                instructions: '',
                selectedItem: '',
                dialog: false,
                exceptions_dialog: false,

                uploadMethods: [{
                        title: 'Mass Update',
                        subtitle: 'Creates or Updates : Persons, Accounts, Loans.',
                        option: "all",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li><li>TRN</li>",
                        show: false
                    },
                    {
                        title: 'Person Upload',
                        subtitle: 'Creates or Updates : Persons ( Demography ) and Accounts.',
                        option: "persons",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>TRN</li>",
                        show: false
                    },
                    {
                        title: 'Loan Upload/Update',
                        subtitle: 'Creates or Updates : Loans.',
                        option: "loans",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li>",
                        show: false
                    },
                    {
                        title: 'Loan Collaterals',
                        subtitle: 'Creates or Updates : Loan Collaterals.',
                        option: "collaterals",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li><li>Loan_Ref_No.</li><li>Cash_Collateral</li><li>Motor_Vehicle_Collateral</li><li>Real_Estate_Collateral</li>",
                        show: false
                    },
                    {
                        title: 'Transactions Upload',
                        subtitle: 'Creates or Updates : Transactions and Sub-Accounts.',
                        option: "transactions-upload",
                        instructions: "<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li>",
                        show: false
                    }
                ],

                headers: [{
                        text: 'Account Number',
                        sortable: false,
                        value: 'account',
                    },
                    {
                        text: 'Error Field',
                        value: 'exception'
                    }
                ],
            };
        },

        created() {},

        beforeDestroy() {},

        methods: {
            ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

            handleFileUpload(val) {
                let index = val
                var i = -1
                //gets selected file object
                this.file = this.$refs.file[index].files;

                //sets upload button by selected option
                for (i = 0; i < this.uploadMethods.length; i++) {
                    if (i == index) {
                        this.uploadMethods[i].show = true
                    } else {
                        this.uploadMethods[i].show = false
                        //reset previous selected file field value
                        this.$refs.file[i].value = ''
                    }
                }

                this.dialogInfo.message = ""
                this.dialogInfo.title = ""
                this.error = false
            },

            showInstructions(item) {
                this.dialog = true;
                this.selectedItem = item;
            },

            validateFile(item) {
                let uploadOption = item.option
                this.ux.option = uploadOption

                this.ux.processing = true
                this.ux.success = false
                this.ux.error = false

                this.ux.progress = 'File is uploading...'
                this.resetValues();
                switch (uploadOption) {
                    case "transactions-upload":
                        this.transactionUpload()
                        break;
                    case "all":
                    case "loans":
                    case "collaterals":
                    case "persons":
                        this.massUpload(uploadOption)
                        break;
                }

            },

            async massUpload(option) {
                let formData = new FormData();
                formData.append('file', this.file[0]);
                try {
                    let response = await MassUploadService.massUpload(option, formData, (event) => {
                        this.uploadProgress = Math.round((100 * event.loaded) / event.total)
                        if (this.uploadProgress == 100) {
                            this.ux.progress = 'Now processing file data...'
                        }
                    })

                    this.ux.success = true

                    if (response.data) {
                        const results = response.data.results || false

                        if (results) {
                            this.ux.totalPersons = results.total_persons;
                            this.ux.totalLoans = results.total_loan_amount;
                            if (results.collaterals || false) {
                                this.ux.totalCC = results.collaterals.total_cash_amount || 0.00;
                                this.ux.totalMVC = results.collaterals.total_motor_vehicle_amount || 0.00;
                                this.ux.totalREC = results.collaterals.total_real_estate_amount || 0.00;
                            }
                        }

                        // const invalidRecords = response.data.invalid_records || []
                        // for (const record in invalidRecords) {
                        //     this.invalidRecords.push(record)
                        // }
                        //     console.log('this.invalidRecords: ', this.invalidRecords);
                        let data = response.data.invalid_records || [];
                        if (data.length > 0) {
                            var res = Object.keys(data).map((key) => [key, data[key].invalid_params[0].field]);
                            this.ux.exceptions = res.map(function (el) {
                                return {
                                    account: el[0],
                                    exception: el[1]
                                };
                            });
                        }
                    }
                    console.info(response.data);

                } catch (e) {
                    if (e.response && e.response.status == 422) {


                    }
                    console.log('fetching data: ', e)
                    this.dialogInfo.message = 'Error in Processing File'
                    this.error = true
                    this.ux.error = true
                    this.file = ""
                } finally {
                    this.$refs.file.forEach(el => el.value = "")
                    this.ux.processing = false
                    this.showButton = true
                    this.dialogInfo.message = '<v-subheader> Successfully Uploaded </v-subheader> <li><b>' + ' ' +
                        this.totalPersons + ' ' + '</b> Persons with Accounts </li> <li><b>' + ' ' + this
                        .totalLoans + ' ' + '</b>Loans in Total </li>'
                }
            },

            async transactionUpload() {

                let formData = new FormData();
                formData.append('file', this.file[0]);

                try {
                    let response = await MassUploadService.transactionUpload(formData, (event) => {
                        this.uploadProgress = Math.round((100 * event.loaded) / event.total)
                        if (this.uploadProgress == 100) {
                            this.ux.progress = 'Now processing file data...'
                        }
                    })

                    this.ux.success = true;

                    this.error = false;

                    if (response.data) {
                        const results = response.data.results || false

                        if (results) {
                            this.ux.countTransactions = results.total_number_of_transactions || 0;
                            this.ux.sumTransactions = results.total_transaction_amount || 0;
                        }

                        let data = response.data.invalid_records;
                        var res = Object.keys(data).map((key) => [key, data[key].invalid_params[0].field]);
                        this.ux.exceptions = res.map(function (el) {
                            return {
                                account: el[0],
                                exception: el[1]
                            };
                        });
                    }

                } catch (e) {

                    console.log('fetching data: ', e)
                    this.dialogInfo.message = 'Error in Processing File'
                    this.error = true
                    this.ux.processing = false
                    this.ux.success = false
                    this.ux.error = true

                } finally {

                    this.$refs.file.forEach(el => el.value = "")
                    this.ux.processing = false
                    this.showButton = true
                    this.dialogInfo.message = '<v-subheader> Successfully Uploaded </v-subheader> <li><b>' +
                        ' ' +
                        this.countTransactions + ' ' + '</b> Sub-Accounts </li> <li><b>' + ' ' + this
                        .sumTransactions + ' ' + '</b>Transactions in Total </li>'
                }
            },

            resetValues() {
                this.ux.exceptions = [];
                this.ux.countTransactions = '';
                this.ux.sumTransactions = '';
                this.ux.totalPersons = '';
                this.ux.totalLoans = '';
                this.ux.totalCC = '';
                this.ux.totalMVC = '';
                this.ux.totalREC = '';
            }
        }
    };
</script>